import React, { useState } from 'react';
import { connect } from 'react-redux';
import { login } from '../../store/actions';
import './Login.css';

function Login({ user, loading, onLogin }) {
	const [username, onEmailChange] = useState('');
	const [password, onPasswordChange] = useState('');

	const buttonDisabled = loading || username.length === 0 || password.length === 0;

	const handleSubmit = () => {
		onLogin({
			grant_type: "password",
			username,
			password
		});
	};

	return (
		<div className="bg-gradient-primary login-wrapper">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-xl-10 col-lg-12 col-md-9">
						<div className="card o-hidden border-0 shadow-lg my-5">
							<div className="card-body p-0">
								<div className="row">
									<div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
									<div className="col-lg-6">
										<div className="p-5">
											<div className="text-center">
												<h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
											</div>
											<form className="user">
												<div className="form-group">
													<input
														type="email"
														id="email"
														name="email"
														className="form-control form-control-user"
														placeholder="Enter Email Address..."
														value={username}
														onChange={e => onEmailChange(e.target.value)} />
												</div>
												<div className="form-group">
													<input
														type="password"
														id="password"
														name="password"
														className="form-control form-control-user"
														placeholder="Password"
														value={password}
														onChange={e => onPasswordChange(e.target.value)} />
												</div>
												<div className="form-group">
													<div className="custom-control custom-checkbox small">
														<input type="checkbox" className="custom-control-input" id="customCheck" />
														<label className="custom-control-label" htmlFor="customCheck">Remember Me</label>
													</div>
												</div>
												<button className="btn btn-primary btn-user btn-block"
														disabled={buttonDisabled}
														onClick={handleSubmit}>
													Login
												</button>
											</form>
											<hr />
											<div className="text-center">
												<a className="small" href="#">Forgot Password?</a>
											</div>
											<div className="text-center">
												<a className="small" href="#">Create an Account!</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = state => {
	return {
		user: state.auth.user,
		loading: state.auth.loading
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onLogin: user => dispatch(login(user))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);