import API from '../../services/api/api';
import { toast } from 'react-toastify';
import utils from '../../services/utils';

import {
	GET_BRANCHES_LIST_START,
	GET_BRANCHES_LIST_SUCCESS,
	GET_BRANCHES_LIST_FAIL,

	GET_BRANCHES_ALL_LIST_START,
	GET_BRANCHES_ALL_LIST_SUCCESS,
	GET_BRANCHES_ALL_LIST_FAIL,

	UPDATE_USER_DATA
} from './actionTypes';

/* branchesList start */
export const getBranchesListStart = () => {
	return {
		type: GET_BRANCHES_LIST_START
	};
};

export const getBranchesListSuccess = branches => {
	return {
		type: GET_BRANCHES_LIST_SUCCESS,
		branches
	};
};

export const getBranchesListFail = () => {
	return {
		type: GET_BRANCHES_LIST_FAIL
	};
};

export const getBranchesList = (urlParams, perPage) => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(getBranchesListStart());
		API.getBranchesList(userData.access_token, urlParams, perPage)
			.then(response => {
				const branches = response.data;
				dispatch(getBranchesListSuccess(branches));
			})
			.catch( error => {
				dispatch(getBranchesListFail());
				console.log(error);
				if(error.response && error.response.status === 401) {
					toast.error(((error.response && error.response.statusText) || 'Something went wrong'), {
						position: toast.POSITION.TOP_RIGHT
					});
					utils.deleteDataFromStorage('TUK_TUK_USER_DATA');
					dispatch({
						type: UPDATE_USER_DATA,
						user: {}
					});
				}
			});

	};
};
/* branchesList end */

/* branchesAllList start */
export const getBranchesAllListStart = () => {
	return {
		type: GET_BRANCHES_ALL_LIST_START
	};
};

export const getBranchesAllListSuccess = branchesAll => {
	return {
		type: GET_BRANCHES_ALL_LIST_SUCCESS,
		branchesAll
	};
};

export const getBranchesAllListFail = () => {
	return {
		type: GET_BRANCHES_ALL_LIST_FAIL
	};
};

export const getBranchesAllList = () => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(getBranchesAllListStart());
		return API.getBranchesAllList(userData.access_token)
			.then(response => {
				const branches = response.data;
				dispatch(getBranchesAllListSuccess(branches));
				return branches;
			})
			.catch( error => {
				dispatch(getBranchesAllListFail());
				console.log(error);
				if(error.response && error.response.status === 401) {
					toast.error(((error.response && error.response.statusText) || 'Something went wrong'), {
						position: toast.POSITION.TOP_RIGHT
					});
					utils.deleteDataFromStorage('TUK_TUK_USER_DATA');
					dispatch({
						type: UPDATE_USER_DATA,
						user: {}
					});
				}
				return [];
			});

	};
};
/* branchesAllList end */