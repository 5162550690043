import {
	GET_SPECIALISTS_LIST_START,
	GET_SPECIALISTS_LIST_SUCCESS,
	GET_SPECIALISTS_LIST_FAIL,

	GET_SPECIALISTS_ALL_LIST_START,
	GET_SPECIALISTS_ALL_LIST_SUCCESS,
	GET_SPECIALISTS_ALL_LIST_FAIL,

	CREATE_SPECIALIST_NOTES_START,
	CREATE_SPECIALIST_NOTES_SUCCESS,
	CREATE_SPECIALIST_NOTES_FAIL,
} from '../actions/actionTypes';
import utils from '../../services/utils';

const initialState = {
	specialists: {},
	isSpecialistsLoading: false,
	specialistsAll: [],
	isSpecialistsAllLoading: false,
	isSpecialistsNoteCreating: false
};

/* specialistsList start */
const getSpecialistsListStart = (state, action) => {
	return utils.updateObject(state, {
		isSpecialistsLoading: true
	});
};

const getSpecialistsListSuccess = (state, action) => {
	return utils.updateObject(state, {
		specialists: action.specialists,
		isSpecialistsLoading: false
	});
};

const getSpecialistsListFail = (state, action) => {
	return utils.updateObject(state, {
		specialists: {},
		isSpecialistsLoading: false
	});
};
/* specialistsList end */

/* specialistsAllList start */
const getSpecialistsAllListStart = (state, action) => {
	return utils.updateObject(state, {
		isSpecialistsAllLoading: true
	});
};

const getSpecialistsAllListSuccess = (state, action) => {
	return utils.updateObject(state, {
		specialistsAll: action.specialistsAll,
		isSpecialistsAllLoading: false
	});
};

const getSpecialistsAllListFail = (state, action) => {
	return utils.updateObject(state, {
		specialistsAll: [],
		isSpecialistsAllLoading: false
	});
};
/* specialistsAllList end */

/* createAllListNotes start */
const createSpecialistNotesStart = (state, action) => {
	return utils.updateObject(state, {
		isSpecialistsNoteCreating: true
	});
};

const createSpecialistNotesSuccess = (state, action) => {
	return utils.updateObject(state, {
		isSpecialistsNoteCreating: false
	});
};

const createSpecialistNotesFail = (state, action) => {
	return utils.updateObject(state, {
		isSpecialistsNoteCreating: false
	});
};
/* createAllListNotes end */

const specialistsReducer = (state = initialState, action = {}) => {
	switch (action.type) {
		case GET_SPECIALISTS_LIST_START: return getSpecialistsListStart(state, action);
		case GET_SPECIALISTS_LIST_SUCCESS: return getSpecialistsListSuccess(state, action);
		case GET_SPECIALISTS_LIST_FAIL: return getSpecialistsListFail(state, action);

		case GET_SPECIALISTS_ALL_LIST_START: return getSpecialistsAllListStart(state, action);
		case GET_SPECIALISTS_ALL_LIST_SUCCESS: return getSpecialistsAllListSuccess(state, action);
		case GET_SPECIALISTS_ALL_LIST_FAIL: return getSpecialistsAllListFail(state, action);

		case CREATE_SPECIALIST_NOTES_START: return createSpecialistNotesStart(state, action);
		case CREATE_SPECIALIST_NOTES_SUCCESS: return createSpecialistNotesSuccess(state, action);
		case CREATE_SPECIALIST_NOTES_FAIL: return createSpecialistNotesFail(state, action);

		default:
			return state;
	}
};

export default specialistsReducer;