import axios from 'axios';
axios.defaults.baseURL = process.env.REACT_APP_API;

const API = {
	login: (userData) => {
		return axios.post(`/back_office_oauth`, userData, {
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Basic ' + process.env.REACT_APP_TOKEN
			}
		});
	},
	refreshToken: (userData) => {
		return axios.post(`/back_office_oauth`, userData, {
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Basic ' + process.env.REACT_APP_TOKEN
			}
		});
	},
	getBusinessCategoryList: (token, urlParams) => {
		let url = '';
		url += '/back-office/business-categories';
		if (urlParams) {
			url += '?' + urlParams;
		}

		return axios.get(url, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}
		});
	},
	getBusinessCategoryById: (id, token) => {
		return axios.get(`/back-office/business-categories`, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}
		});
	},
	editBusinessCategoryById: (id, token, data) => {
		return axios.patch(`/back-office/business-categories/${id}`, data, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}
		});
	},
	orderBusinessCategoryList: (token, data) => {
		return axios.patch(`/back-office/business-categories`, data, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}
		});
	},
	getBusinessServiceList: (token, urlParams, page_size) => {
		let url = '';
		url += '/back-office/business-services';
		if (page_size) {
			url += `?page_size=${page_size}`;
		}
		if (urlParams) {
			url += (page_size ? '&' : '?') + urlParams;
		}

		return axios.get(url, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}
		});
	},
	getBusinessServiceById: (id, token) => {
		return axios.get(`/back-office/business-services`, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}
		});
	},
	editBusinessServiceById: (id, token, data) => {
		return axios.patch(`/back-office/business-services/${id}`, data, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}
		});
	},
	getSpecialistsList: (token, urlParams, page_size) => {
		let url = '';
		url += '/back-office/specialists';
		if (page_size) {
			url += `?page_size=${page_size}`;
		}
		if (urlParams) {
			url += (page_size ? '&' : '?') + urlParams;
		}

		return axios.get(url, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}
		});
	},
	getSpecialistsAllList: token => {
		return axios.get('/back-office/row-specialists', {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}
		});
	},
	getSpecialistNotes: (token, specialist_id) => {
		return axios.get(`/back-office/specialists/${specialist_id}/notes`, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}
		});
	},
	createSpecialistNotes: (token, id ,data) => {
		return axios.post(`/back-office/specialists/${id}/notes`, data, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}
		});
	},
	getUsersList: (token, urlParams) => {
		let url = '';
		url += '/back-office/row-users';
		if (urlParams) {
			url += '?' + urlParams;
		}

		return axios.get(url, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}
		});
	},
	getBranchesAllList: token => {
		return axios.get('/back-office/row-branches', {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}
		});
	},
	getBranchesList: (token, urlParams, page_size) => {
		let url = '';
		url += '/back-office/branches';
		if (page_size) {
			url += `?page_size=${page_size}`;
		}
		if (urlParams) {
			url += (page_size ? '&' : '?') + urlParams;
		}

		return axios.get(url, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}
		});
	},
	getBannersList: token => {
		return axios.get(`/back-office/banners`, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}
		});
	},
	getBannerById: (id, token) => {
		return axios.get(`/back-office/banners`, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}
		});
	},
	createBanner: (token, data) => {
		return axios.post(`/back-office/banners`, data, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}
		});
	},
	editBannerById: (token, data, id) => {
		return axios.patch(`/back-office/banners/${id}`, data, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}
		});
	},
	createNotification: (token, data) => {
		return axios.post(`/back-office/notifications`, data, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}
		});
	}
};

export default API;
