import React from 'react';

import './Toolbar.css';

const toolbar = ({ onLogout }) => {
    return (
        <div className="navbar  navbar-light bg-white topbar mb-4 static-top shadow">
            <button className="btn logout" type="button" onClick={onLogout}>
                <i className="fas fa-sign-out-alt fa-sm fa-fw text-gray-400"></i>
            </button>
        </div>
    );
};


export default toolbar;
