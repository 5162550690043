import {
	GET_BRANCHES_LIST_START,
	GET_BRANCHES_LIST_SUCCESS,
	GET_BRANCHES_LIST_FAIL,

	GET_BRANCHES_ALL_LIST_START,
	GET_BRANCHES_ALL_LIST_SUCCESS,
	GET_BRANCHES_ALL_LIST_FAIL,
} from '../actions/actionTypes';
import utils from '../../services/utils';

const initialState = {
	branches: {},
	isBranchesLoading: false,
	branchesAll: [],
	isBranchesAllLoading: false
};

/* branchesList start */
const getBranchesListStart = (state, action) => {
	return utils.updateObject(state, {
		isBranchesLoading: true
	});
};

const getBranchesListSuccess = (state, action) => {
	return utils.updateObject(state, {
		branches: action.branches,
		isBranchesLoading: false
	});
};

const getBranchesListFail = (state, action) => {
	return utils.updateObject(state, {
		branches: {},
		isBranchesLoading: false
	});
};
/* branchesList end */

/* branchesAllList start */
const getBranchesAllListStart = (state, action) => {
	return utils.updateObject(state, {
		isBranchesAllLoading: true
	});
};

const getBranchesAllListSuccess = (state, action) => {
	return utils.updateObject(state, {
		branchesAll: action.branchesAll,
		isBranchesAllLoading: false
	});
};

const getBranchesAllListFail = (state, action) => {
	return utils.updateObject(state, {
		branchesAll: [],
		isBranchesAllLoading: false
	});
};
/* branchesAllList end */

const branchesReducer = (state = initialState, action = {}) => {
	switch (action.type) {
		case GET_BRANCHES_LIST_START: return getBranchesListStart(state, action);
		case GET_BRANCHES_LIST_SUCCESS: return getBranchesListSuccess(state, action);
		case GET_BRANCHES_LIST_FAIL: return getBranchesListFail(state, action);

		case GET_BRANCHES_ALL_LIST_START: return getBranchesAllListStart(state, action);
		case GET_BRANCHES_ALL_LIST_SUCCESS: return getBranchesAllListSuccess(state, action);
		case GET_BRANCHES_ALL_LIST_FAIL: return getBranchesAllListFail(state, action);

		default:
			return state;
	}
};

export default branchesReducer;