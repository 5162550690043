import API from '../../services/api/api';
import { toast } from 'react-toastify';
import utils from '../../services/utils';

import {
	GET_BUSINESS_CATEGORY_LIST_START,
	GET_BUSINESS_CATEGORY_LIST_SUCCESS,
	GET_BUSINESS_CATEGORY_LIST_FAIL,

	GET_BUSINESS_CATEGORY_BY_ID_START,
	GET_BUSINESS_CATEGORY_BY_ID_SUCCESS,
	GET_BUSINESS_CATEGORY_BY_ID_FAIL,

	EDIT_BUSINESS_CATEGORY_BY_ID_START,
	EDIT_BUSINESS_CATEGORY_BY_ID_SUCCESS,
	EDIT_BUSINESS_CATEGORY_BY_ID_FAIL,

	ORDER_BUSINESS_CATEGORY_LIST_START,
	ORDER_BUSINESS_CATEGORY_LIST_SUCCESS,
	ORDER_BUSINESS_CATEGORY_LIST_FAIL,

	GET_BUSINESS_SERVICE_LIST_START,
	GET_BUSINESS_SERVICE_LIST_SUCCESS,
	GET_BUSINESS_SERVICE_LIST_FAIL,

	GET_BUSINESS_SERVICE_BY_ID_START,
	GET_BUSINESS_SERVICE_BY_ID_SUCCESS,
	GET_BUSINESS_SERVICE_BY_ID_FAIL,

	EDIT_BUSINESS_SERVICE_BY_ID_START,
	EDIT_BUSINESS_SERVICE_BY_ID_SUCCESS,
	EDIT_BUSINESS_SERVICE_BY_ID_FAIL,

	UPDATE_USER_DATA
} from './actionTypes';

/* categoryList start */
export const getBusinessCategoryListStart = () => {
	return {
		type: GET_BUSINESS_CATEGORY_LIST_START
	};
};

export const getBusinessCategoryListSuccess = businessCategoryList => {
	return {
		type: GET_BUSINESS_CATEGORY_LIST_SUCCESS,
		businessCategoryList
	};
};

export const getBusinessCategoryListFail = () => {
	return {
		type: GET_BUSINESS_CATEGORY_LIST_FAIL
	};
};

export const getBusinessCategoryList = (urlParams) => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(getBusinessCategoryListStart());
		API.getBusinessCategoryList(userData.access_token, urlParams)
			.then(response => {
				dispatch(getBusinessCategoryListSuccess(response.data));
			})
			.catch( error => {
				dispatch(getBusinessCategoryListFail());
				console.log(error);
				if(error.response && error.response.status === 401) {
					toast.error(((error.response && error.response.statusText) || 'Something went wrong'), {
						position: toast.POSITION.TOP_RIGHT
					});
					utils.deleteDataFromStorage('TUK_TUK_USER_DATA');
					dispatch({
						type: UPDATE_USER_DATA,
						user: {}
					});
				}
			});

	};
};
/* categoryList end */

/* get business category by id start */
export const getBusinessCategoryByIdStart = () => {
	return {
		type: GET_BUSINESS_CATEGORY_BY_ID_START
	};
};

export const getBusinessCategoryByIdSuccess = businessCategory => {
	return {
		type: GET_BUSINESS_CATEGORY_BY_ID_SUCCESS,
		businessCategory
	};
};

export const getBusinessCategoryByIdFail = () => {
	return {
		type: GET_BUSINESS_CATEGORY_BY_ID_FAIL
	};
};

export const getBusinessCategoryById = id => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(getBusinessCategoryByIdStart());
		return API.getBusinessCategoryById(id, userData.access_token)
				.then(response => {
					const businessCategoriesList = response.data || [];
					const businessCategory = businessCategoriesList.filter(b => (b.id + '') === id);
					if(businessCategory && businessCategory[0]) {
						dispatch(getBusinessCategoryByIdSuccess(businessCategory[0]));
						return businessCategory[0];
					} else {
						dispatch(getBusinessCategoryByIdFail());
						toast.error(('Something went wrong fetching data'), {
							position: toast.POSITION.TOP_RIGHT
						});
						return {};
					}
				})
				.catch( error => {
					dispatch(getBusinessCategoryByIdFail());
					console.log(error);
					if(error.response && error.response.status === 401) {
						toast.error(((error.response && error.response.statusText) || 'Something went wrong'), {
							position: toast.POSITION.TOP_RIGHT
						});
						utils.deleteDataFromStorage('TUK_TUK_USER_DATA');
						dispatch({
							type: UPDATE_USER_DATA,
							user: {}
						});
					}
					return {};
				});

	};
};
/* get business category by id end */

/* edit business category by id start */
export const editBusinessCategoryByIdStart = () => {
	return {
		type: EDIT_BUSINESS_CATEGORY_BY_ID_START
	};
};

export const editBusinessCategoryByIdSuccess = () => {
	return {
		type: EDIT_BUSINESS_CATEGORY_BY_ID_SUCCESS
	};
};

export const editBusinessCategoryByIdFail = () => {
	return {
		type: EDIT_BUSINESS_CATEGORY_BY_ID_FAIL
	};
};

export const editBusinessCategoryById = (id, data) => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(editBusinessCategoryByIdStart());
		return API.editBusinessCategoryById(id, userData.access_token, data)
			.then(response => {
				dispatch(editBusinessCategoryByIdSuccess());
				toast.success('Business Category edited successfully', {
					position: toast.POSITION.TOP_RIGHT
				});
				return response;
			})
			.catch( error => {
				dispatch(editBusinessCategoryByIdFail());
				console.log(error);
				if(error.response && error.response.status === 401) {
					toast.error(((error.response && error.response.statusText) || 'Something went wrong'), {
						position: toast.POSITION.TOP_RIGHT
					});
					utils.deleteDataFromStorage('TUK_TUK_USER_DATA');
					dispatch({
						type: UPDATE_USER_DATA,
						user: {}
					});
				}
				return {};
			});

	};
};
/* edit business category by id end */

/* order business category list start */
export const orderBusinessCategoryListStart = () => {
	return {
		type: ORDER_BUSINESS_CATEGORY_LIST_START
	};
};

export const orderBusinessCategoryListSuccess = () => {
	return {
		type: ORDER_BUSINESS_CATEGORY_LIST_SUCCESS
	};
};

export const orderBusinessCategoryListFail = () => {
	return {
		type: ORDER_BUSINESS_CATEGORY_LIST_FAIL
	};
};

export const orderBusinessCategoryList = data => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(orderBusinessCategoryListStart());
		return API.orderBusinessCategoryList(userData.access_token, data)
			.then(response => {
				dispatch(orderBusinessCategoryListSuccess());
				toast.success('Business Category Ordering saved successfully', {
					position: toast.POSITION.TOP_RIGHT
				});
				return response;
			})
			.catch( error => {
				dispatch(orderBusinessCategoryListFail());
				console.log(error);
				if(error.response && error.response.status === 401) {
					toast.error(((error.response && error.response.statusText) || 'Something went wrong'), {
						position: toast.POSITION.TOP_RIGHT
					});
					utils.deleteDataFromStorage('TUK_TUK_USER_DATA');
					dispatch({
						type: UPDATE_USER_DATA,
						user: {}
					});
				}
				return {};
			});

	};
};
/* order business category list end */

/* serviceList start */
export const getBusinessServiceListStart = () => {
	return {
		type: GET_BUSINESS_SERVICE_LIST_START
	};
};

export const getBusinessServiceListSuccess = businessServices => {
	return {
		type: GET_BUSINESS_SERVICE_LIST_SUCCESS,
		businessServices
	};
};

export const getBusinessServiceListFail = () => {
	return {
		type: GET_BUSINESS_SERVICE_LIST_FAIL
	};
};

export const getBusinessServiceList = (urlParams, perPage) => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(getBusinessServiceListStart());
		API.getBusinessServiceList(userData.access_token, urlParams, perPage)
			.then(response => {
				const businessServices = response.data;
				dispatch(getBusinessServiceListSuccess(businessServices));
			})
			.catch( error => {
				dispatch(getBusinessServiceListFail());
				console.log(error);
				if(error.response && error.response.status === 401) {
					toast.error(((error.response && error.response.statusText) || 'Something went wrong'), {
						position: toast.POSITION.TOP_RIGHT
					});
					utils.deleteDataFromStorage('TUK_TUK_USER_DATA');
					dispatch({
						type: UPDATE_USER_DATA,
						user: {}
					});
				}
			});

	};
};
/* serviceList end */

/* get business service by id start */
export const getBusinessServiceByIdStart = () => {
	return {
		type: GET_BUSINESS_SERVICE_BY_ID_START
	};
};

export const getBusinessServiceByIdSuccess = businessService => {
	return {
		type: GET_BUSINESS_SERVICE_BY_ID_SUCCESS,
		businessService
	};
};

export const getBusinessServiceByIdFail = () => {
	return {
		type: GET_BUSINESS_SERVICE_BY_ID_FAIL
	};
};

export const getBusinessServiceById = id => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(getBusinessServiceByIdStart());
		return API.getBusinessServiceById(id, userData.access_token)
			.then(response => {
				const businessServiceList = (response.data && response.data._embedded && response.data._embedded.business_service) || [];
				const businessService = businessServiceList.filter(b => (b.id + '') === id);
				if(businessService && businessService[0]) {
					dispatch(getBusinessServiceByIdSuccess(businessService[0]));
					return businessService[0];
				} else {
					dispatch(getBusinessServiceByIdFail());
					toast.error(('Something went wrong fetching data'), {
						position: toast.POSITION.TOP_RIGHT
					});
					return {};
				}
			})
			.catch( error => {
				dispatch(getBusinessServiceByIdFail());
				console.log(error);
				if(error.response && error.response.status === 401) {
					toast.error(((error.response && error.response.statusText) || 'Something went wrong'), {
						position: toast.POSITION.TOP_RIGHT
					});
					utils.deleteDataFromStorage('TUK_TUK_USER_DATA');
					dispatch({
						type: UPDATE_USER_DATA,
						user: {}
					});
				}
				return {};
			});
	};
};
/* get business service by id end */

/* edit business service by id start */
export const editBusinessServiceByIdStart = () => {
	return {
		type: EDIT_BUSINESS_SERVICE_BY_ID_START
	};
};

export const editBusinessServiceByIdSuccess = () => {
	return {
		type: EDIT_BUSINESS_SERVICE_BY_ID_SUCCESS
	};
};

export const editBusinessServiceByIdFail = () => {
	return {
		type: EDIT_BUSINESS_SERVICE_BY_ID_FAIL
	};
};

export const editBusinessServiceById = (id, data) => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(editBusinessServiceByIdStart());
		return API.editBusinessServiceById(id, userData.access_token, data)
			.then(response => {
				dispatch(editBusinessServiceByIdSuccess());
				toast.success('Business Service edited successfully', {
					position: toast.POSITION.TOP_RIGHT
				});
				return response;
			})
			.catch( error => {
				dispatch(editBusinessServiceByIdFail());
				console.log(error);
				if(error.response && error.response.status === 401) {
					toast.error(((error.response && error.response.statusText) || 'Something went wrong'), {
						position: toast.POSITION.TOP_RIGHT
					});
					utils.deleteDataFromStorage('TUK_TUK_USER_DATA');
					dispatch({
						type: UPDATE_USER_DATA,
						user: {}
					});
				}
				return {};
			});

	};
};
/* edit business service by id end */