import API from '../../services/api/api';
import { toast } from 'react-toastify';
import utils from '../../services/utils';

import {
	GET_USERS_LIST_START,
	GET_USERS_LIST_SUCCESS,
	GET_USERS_LIST_FAIL,

	UPDATE_USER_DATA
} from './actionTypes';


/* usersList start */
export const getUsersListStart = () => {
	return {
		type: GET_USERS_LIST_START
	};
};

export const getUsersListSuccess = usersList => {
	return {
		type: GET_USERS_LIST_SUCCESS,
		usersList
	};
};

export const getUsersListFail = () => {
	return {
		type: GET_USERS_LIST_FAIL
	};
};

export const getUsersList = urlParams => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(getUsersListStart());
		API.getUsersList(userData.access_token, urlParams)
			.then(response => {
				const usersList = response.data;
				dispatch(getUsersListSuccess(usersList));
			})
			.catch( error => {
				dispatch(getUsersListFail());
				console.log(error);
				if(error.response && error.response.status === 401) {
					toast.error(((error.response && error.response.statusText) || 'Something went wrong'), {
						position: toast.POSITION.TOP_RIGHT
					});
					utils.deleteDataFromStorage('TUK_TUK_USER_DATA');
					dispatch({
						type: UPDATE_USER_DATA,
						user: {}
					});
				}
			});

	};
};
/* usersList end */