import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createSpecialistNotes, getSpecialistNotes } from '../../store/actions';
import PropTypes from 'prop-types';

import './MessageBox.css';

function MessageBox({specialistId, loading, onCreateSpecialistNotes, onGetSpecialistNotes}) {
	const [text, onTextChange] = useState('');

	useEffect(() => {
		let isMounted = true;
		onGetSpecialistNotes(specialistId).then(notesData => {
			const lastNotesData = (notesData || []).slice(-1).pop() || {};
			if(lastNotesData.text) {
				if(isMounted) {
					onTextChange(lastNotesData.text);
				}
			}
		});
		return () => { isMounted = false };
	},
	[onGetSpecialistNotes, specialistId]);

	const handleSendMessage = () => {
		onCreateSpecialistNotes(specialistId, {text});
	};

	return (
		<div className="message-box">
			<textarea rows="1" onChange={e=> onTextChange(e.target.value)} value={text}></textarea>
			<button className="btn btn-primary btn-sm btn-block"
					disabled={!text || loading}
					onClick={handleSendMessage}>
				Send
			</button>
		</div>
	);
}

MessageBox.propTypes = {
	specialistId: PropTypes.number,
	loading: PropTypes.bool,
	onCreateSpecialistNotes: PropTypes.func,
	onGetSpecialistNotes: PropTypes.func
};

const mapStateToProps = state => {
	return {
		loading: state.specialists.isSpecialistsNoteCreating
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onCreateSpecialistNotes: (id, data) => dispatch(createSpecialistNotes(id, data)),
		onGetSpecialistNotes: id => dispatch(getSpecialistNotes(id))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageBox);