import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import './index.css';
import './assets/fonts/fontawesome/css/all.min.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import mainReducer from './store/reducers/main';
import authReducer from './store/reducers/auth';
import businessReducer from './store/reducers/business';
import specialistsReducer from './store/reducers/specialists';
import branchesReducer from './store/reducers/branches';
import bannersReducer from './store/reducers/banners';
import notificationsReducer from './store/reducers/notifications';

const rootReducer = combineReducers({
	main: mainReducer,
	auth: authReducer,
	business: businessReducer,
	specialists: specialistsReducer,
	branches: branchesReducer,
	banners: bannersReducer,
	notifications: notificationsReducer
});

const store = createStore(rootReducer, {}, applyMiddleware(thunk));


const app = (
	<Provider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
