import API from '../../services/api/api';
import { toast } from 'react-toastify';
import utils from '../../services/utils';

import {
	GET_SPECIALISTS_LIST_START,
	GET_SPECIALISTS_LIST_SUCCESS,
	GET_SPECIALISTS_LIST_FAIL,

	GET_SPECIALISTS_ALL_LIST_START,
	GET_SPECIALISTS_ALL_LIST_SUCCESS,
	GET_SPECIALISTS_ALL_LIST_FAIL,

	CREATE_SPECIALIST_NOTES_START,
	CREATE_SPECIALIST_NOTES_SUCCESS,
	CREATE_SPECIALIST_NOTES_FAIL,

	UPDATE_USER_DATA
} from './actionTypes';

/* specialistsList start */
export const getSpecialistsListStart = () => {
	return {
		type: GET_SPECIALISTS_LIST_START
	};
};

export const getSpecialistsListSuccess = specialists => {
	return {
		type: GET_SPECIALISTS_LIST_SUCCESS,
		specialists
	};
};

export const getSpecialistsListFail = () => {
	return {
		type: GET_SPECIALISTS_LIST_FAIL
	};
};

export const getSpecialistsList = (urlParams, perPage) => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(getSpecialistsListStart());
		API.getSpecialistsList(userData.access_token, urlParams, perPage)
			.then(response => {
				const specialists = response.data;
				dispatch(getSpecialistsListSuccess(specialists));
			})
			.catch( error => {
				dispatch(getSpecialistsListFail());
				console.log(error);
				if(error.response && error.response.status === 401) {
					utils.deleteDataFromStorage('TUK_TUK_USER_DATA');
					dispatch({
						type: UPDATE_USER_DATA,
						user: {}
					});
				}
				toast.error(((error.response && error.response.statusText) || 'Something went wrong'), {
					position: toast.POSITION.TOP_RIGHT
				});
			});

	};
};
/* specialistsList end */

/* specialistsAllList start */
export const getSpecialistsAllListStart = () => {
	return {
		type: GET_SPECIALISTS_ALL_LIST_START
	};
};

export const getSpecialistsAllListSuccess = specialistsAll => {
	return {
		type: GET_SPECIALISTS_ALL_LIST_SUCCESS,
		specialistsAll
	};
};

export const getSpecialistsAllListFail = () => {
	return {
		type: GET_SPECIALISTS_ALL_LIST_FAIL
	};
};

export const getSpecialistsAllList = () => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(getSpecialistsAllListStart());
		return API.getSpecialistsAllList(userData.access_token)
			.then(response => {
				const specialists = response.data;
				dispatch(getSpecialistsAllListSuccess(specialists));
				return specialists;
			})
			.catch( error => {
				dispatch(getSpecialistsAllListFail());
				console.log(error);
				if(error.response && error.response.status === 401) {
					toast.error(((error.response && error.response.statusText) || 'Something went wrong'), {
						position: toast.POSITION.TOP_RIGHT
					});
					utils.deleteDataFromStorage('TUK_TUK_USER_DATA');
					dispatch({
						type: UPDATE_USER_DATA,
						user: {}
					});
				}
				return [];
			});
	};
};
/* specialistsAllList end */

/* get specialist note */
export const getSpecialistNotes = specialist_id => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		return API.getSpecialistNotes(userData.access_token, specialist_id)
			.then(response => {
				return response.data;
			})
			.catch( error => {
				console.log(error);
				return [];
			});
	};
};
/* get specialist note end */

/* createAllListNotes start */
export const createSpecialistNotesStart = () => {
	return {
		type: CREATE_SPECIALIST_NOTES_START
	};
};

export const createSpecialistNotesSuccess = () => {
	return {
		type: CREATE_SPECIALIST_NOTES_SUCCESS
	};
};

export const createSpecialistNotesFail = () => {
	return {
		type: CREATE_SPECIALIST_NOTES_FAIL
	};
};

export const createSpecialistNotes = (id, data) => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(createSpecialistNotesStart());
		API.createSpecialistNotes(userData.access_token, id ,data)
			.then(response => {
				toast.success('Sended successfully', {
					position: toast.POSITION.TOP_RIGHT
				});
				dispatch(createSpecialistNotesSuccess());
			})
			.catch( error => {
				dispatch(createSpecialistNotesFail());
				console.log(error);
				if(error.response && error.response.status === 401) {
					toast.error(((error.response && error.response.statusText) || 'Something went wrong'), {
						position: toast.POSITION.TOP_RIGHT
					});
					utils.deleteDataFromStorage('TUK_TUK_USER_DATA');
					dispatch({
						type: UPDATE_USER_DATA,
						user: {}
					});
				}
			});

	};
};
/* createAllListNotes end */