const config = {
    STATUSES: [
        {label: 'Active', value: 'active'},
        {label: 'Pending', value: 'pending'},
        {label: 'Disabled', value: 'disabled'},
        {label: 'Created', value: 'created'}
    ],
    FILTER_STATUSES: [
        {label: 'Active', value: 'active'},
        {label: 'Pending', value: 'pending'},
        {label: 'Disabled', value: 'disabled'}
    ]
};

export default config;