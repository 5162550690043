import React, {useState, useEffect, Fragment} from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import { getBusinessServiceList, getBusinessCategoryList } from '../../store/actions';
import utils from '../../services/utils';
import config from '../../services/config';
import PropTypes from 'prop-types';
import './BusinessServiceList.css';

import Loader from '../../components/UI/Loader/Loader';

function BusinessServiceList({
		businessServices,
		businessCategoryList,
		loading,
		onGetBusinessServiceList,
		onGetBusinessCategoryList
	}) {
	const [urlParams, urlParamsChange] = useState('');
	const [activePage, activePageChange] = useState(1);
	const [status, statusChange] = useState('-1');
	const [category_id, categoryChange] = useState('-1');
	useEffect(() => onGetBusinessServiceList(null, null),
		[onGetBusinessServiceList]);

	useEffect(() => onGetBusinessCategoryList(),
		[onGetBusinessCategoryList]);

	useEffect(() => {
		const pageParams = utils.processUrlParams(urlParams, activePage);

		onGetBusinessServiceList(pageParams.urlParams, null);
	}, [onGetBusinessServiceList, activePage, urlParams]);

	const handlePageChange = page => {
		const pageParams = utils.processUrlParams(urlParams, page);

		urlParamsChange(pageParams.urlParams);
		activePageChange(page);
	};

	const handleFilterChange = (e, name) => {
		const pageParams = utils.processUrlParams(urlParams, 1, {[name]: e.target.value});

		urlParamsChange(pageParams.urlParams);
		activePageChange(1);
		switch(name) {
			case "status":
				statusChange(e.target.value);
				break;
			case "category_id":
				categoryChange(e.target.value);
				break;
			default:
				return null;
		}
	};


	const businessServiceList = (businessServices._embedded && businessServices._embedded.business_service) || [];
	const page_size = businessServices.page_size  || 25;
	const total_items = businessServices.total_items  || 1;

	return (
		<div className="container-fluid">
			<div className="card shadow">
				<div className="card-header py-3">
					<div className="row">
						<div className="col-sm-12 col-md-6">
							<h1 className="h3 text-gray-800">Business Services</h1>
						</div>
						<div className="col-sm-12 col-md-3">
							<div className="form-group">
								<label>Status</label>
								<select className="form-control" id="status" value={status} onChange={e => handleFilterChange(e, "status")}>
									<option key="-1" value="-1">Select</option>
									{config.FILTER_STATUSES.map(e => <option key={e.value} value={e.value}>{e.label}</option>)}
								</select>
							</div>
						</div>
						<div className="col-sm-12 col-md-3">
							<div className="form-group">
								<label>Category</label>
								<select className="form-control" id="status" value={category_id} onChange={e => handleFilterChange(e, "category_id")}>
									<option key="-1" value="-1">Select</option>
									{businessCategoryList.map(e => <option key={e.id} value={e.id}>{e.title.text}</option>)}
								</select>
							</div>
						</div>
					</div>
				</div>
				<div className="card-body">
					<div className="row">
						{loading ?
							<Loader /> :
							<div className="col-sm-12 dataTables_container">
								<div className="dataTables_wrapper">
									<table className="table table-bordered dataTable" id="dataTable" width="100%" cellSpacing="0">
										{businessServiceList.length ?
											<Fragment>
												<thead>
													<tr role="row">
														<th>Name</th>
														<th>Category</th>
														<th>Status</th>
														<th className="created-at">Created</th>
													</tr>
												</thead>
												<tfoot>
													<tr>
														<th>Name</th>
														<th>Category</th>
														<th>Status</th>
														<th>Created</th>
													</tr>
												</tfoot>
											</Fragment> : null
										}

										<tbody>
											{businessServiceList.map(item => (
												<tr role="row" className="odd" key={item.id}>
													<td><NavLink exact to={`/business-service/${item.id}`}>{(item.title && item.title.translations && item.title.translations['eng']) || 'N/A'}</NavLink></td>
													<td>{(item.category && item.category.translations && item.category.translations['eng']) || 'N/A'}</td>
													<td>{item.status || 'N/A'}</td>
													<td>{item.created_at || 'N/A'}</td>
												</tr>
											))}
										</tbody>

										{businessServiceList.length === 0 ?
											<tbody>
												<tr role="row" className="odd">
													<td colSpan="4">
														<div className="empty-list">List is empty</div>
													</td>
												</tr>
											</tbody> : null
										}
									</table>
								</div>
							</div>
						}
					</div>
					{loading || businessServiceList.length === 0 ?
						null :
						<div className="row">
							<div className="col-sm-12 col-md-5"></div>
							<div className="col-sm-12 col-md-7">
								<div className="dataTables_paginate paging_simple_numbers">
									<Pagination
										innerClass="pagination float-right"
										itemClass="page-item"
										linkClass="page-link"
										activePage={activePage}
										itemsCountPerPage={page_size}
										totalItemsCount={total_items}
										pageRangeDisplayed={5}
										onChange={handlePageChange}
										/>
								</div>
							</div>
						</div>
					}
				</div>
			</div>
		</div>
	);
}

BusinessServiceList.propTypes = {
	businessCategoryList: PropTypes.array,
	businessServices: PropTypes.object,
	loading: PropTypes.bool,
	onGetBusinessServiceList: PropTypes.func,
	onGetBusinessCategoryList: PropTypes.func
};

const mapStateToProps = state => {
	return {
		businessCategoryList: state.business.businessCategoryList,
		businessServices: state.business.businessServices,
		loading: state.business.isServiceLoading
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetBusinessServiceList: (urlParams, perPage) => dispatch(getBusinessServiceList(urlParams, perPage)),
		onGetBusinessCategoryList: () => dispatch(getBusinessCategoryList())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessServiceList);