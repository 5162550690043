import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';

import './Navigation.css';

function navigation() {
	return (
		<Fragment>
			<ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
				<NavLink className="sidebar-brand d-flex align-items-center justify-content-center" strict to="/">
					<div className="sidebar-brand-text mx-3">DoBooki</div>
				</NavLink>
				<hr className="sidebar-divider my-0" />
				<li className="nav-item sidebar-nav"><NavLink strict to="/specialists" className="nav-link">Specialists</NavLink></li>
				<li className="nav-item sidebar-nav"><NavLink strict to="/branches" className="nav-link">Branches</NavLink></li>
				<li className="nav-item sidebar-nav"><NavLink strict to="/banners" className="nav-link">Banners</NavLink></li>
				<li className="nav-item sidebar-nav"><NavLink strict to="/business-categories" className="nav-link">Business Categories</NavLink></li>
				<li className="nav-item sidebar-nav"><NavLink strict to="/business-services" className="nav-link">Business Services</NavLink></li>
				<li className="nav-item sidebar-nav"><NavLink strict to="/notifications/create" className="nav-link">Create Notifications</NavLink></li>
				<hr className="sidebar-divider" />
			</ul>
		</Fragment>
	);
}


export default navigation;