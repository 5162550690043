import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { getBusinessCategoryById, editBusinessCategoryById } from '../../store/actions';
import config from '../../services/config';
import PropTypes from 'prop-types';
import './BusinessCategory.css';

import Loader from '../../components/UI/Loader/Loader';

function BusinessCategory({
	businessCategory,
	isLoading,
	onGetBusinessCategory,
	onEditBusinessCategory,
	isEditing,
	location,
	history}) {
	const [status, statusChange] = useState('active');
	const [title, titleChange] = useState('');

	const handleFieldChange = (e, f) => {
		f(e.target.value);
	};
	const handleSaveChanges = () => {
		const data = {status};
		onEditBusinessCategory(businessCategory.id, data).then(businessCategory => {
			history.push('/business-categories');
		})
	};

	useEffect(() => {
		const id = (location && location.pathname.split('/business-category/')[1]) || null;
		onGetBusinessCategory(id).then(businessCategory => {
			statusChange(businessCategory.status);
			titleChange((businessCategory.title && businessCategory.title.translations && businessCategory.title.translations['eng']) || '');
		});
	},
	[onGetBusinessCategory, location]);



	return (
		<Fragment>
			{isLoading ?
				<div className="loader-wrapper">
					<Loader />
				</div> :
				<Fragment>
					<div className="container-fluid fields-wrapper">
						<div className="mb-4">
							<h1 className="h3 mb-2 text-gray-800">{title || ''}</h1>
							{businessCategory.created_at ? <p className="mb-0 text-gray-800">Created: {businessCategory.created_at}</p> : null}
						</div>

						<div className="row">
							{/*
								<div className="col-sm-12 col-md-6">
									<div className="form-group">
										<label>Title<span>*</span></label>
										<input type="text"
											   className={"form-control " + (title.length === 0 ? "field-error" : "")}
											   id="title"
											   value={title}
											   onChange={e => handleFieldChange(e, titleChange)} />
										{
											title.length === 0 ?
												<div className="form-control-errors-wrapper">
													<p>Title is required.</p>
												</div> :
												null
										}

									</div>
								</div>
							 */}
							<div className="col-sm-12 col-md-6">
								<div className="form-group">
									<label>Status</label>
									<select className="form-control" id="status" value={status} onChange={e => handleFieldChange(e, statusChange)}>
										{config.STATUSES.map(e => <option key={e.value} value={e.value}>{e.label}</option>)}
									</select>
								</div>
							</div>
						</div>
					</div>

					<div className="container-fluid">
						<div className="row">
							<div className="col-lg-12">
								<button type="submit"
										className="btn btn-primary mb-2 float-left"
										onClick={() => history.push('/business-categories')}>Back</button>
								<button type="submit"
										disabled={isEditing || title.length === 0}
										className="btn btn-primary mb-2 float-right"
										onClick={handleSaveChanges}>Save</button>
							</div>
						</div>
					</div>

				</Fragment>
			}
		</Fragment>

	);
}

BusinessCategory.propTypes = {
	businessCategory: PropTypes.object,
	isLoading: PropTypes.bool,
	isEditing: PropTypes.bool,
	onGetBusinessCategory: PropTypes.func,
	onEditBusinessCategory: PropTypes.func
};

const mapStateToProps = state => {
	return {
		businessCategory: state.business.businessCategory,
		isLoading: state.business.businessCategoryLoading,
		isEditing: state.business.isBusinessCategoryEditing
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetBusinessCategory: id => dispatch(getBusinessCategoryById(id)),
		onEditBusinessCategory: (id, data) => dispatch(editBusinessCategoryById(id, data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessCategory);