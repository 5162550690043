import React, {useState, useEffect, Fragment} from 'react';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import { getSpecialistsList, getBusinessCategoryList } from '../../store/actions';
import utils from '../../services/utils';
import config from '../../services/config';
import PropTypes from 'prop-types';
import ReactTooltip from "react-tooltip";
import './SpecialistsList.css';

import MessageBox from '../../components/MessageBox/MessageBox';
import Loader from '../../components/UI/Loader/Loader';

function SpecialistsList({
		specialists,
		businessCategoryList,
		loading,
		onGetSpecialistsList,
		onGetBusinessCategoryList
	}) {
	const [urlParams, urlParamsChange] = useState('');
	const [activePage, activePageChange] = useState(1);
	const [status, statusChange] = useState('-1');
	const [category_ids, categoryChange] = useState('-1');
	const [has_no_working_day, hasNoWorkingDayChange] = useState('-1');
	const [service_at_user_location, serviceAtUserLocationChange] = useState('-1');
	const [search, searchChange] = useState('');

	useEffect(() => onGetSpecialistsList(null, null),
		[onGetSpecialistsList]);

	useEffect(() => onGetBusinessCategoryList(),
		[onGetBusinessCategoryList]);

	useEffect(() => {
		const pageParams = utils.processUrlParams(urlParams, activePage);

		onGetSpecialistsList(pageParams.urlParams, null);
	}, [onGetSpecialistsList, activePage, urlParams]);

	const handlePageChange = page => {
		const pageParams = utils.processUrlParams(urlParams, page);

		urlParamsChange(pageParams.urlParams);
		activePageChange(page);
	};

	const handleFilterChange = (e, name) => {
		const pageParams = utils.processUrlParams(urlParams, 1, {[name]: e.target.value});

		urlParamsChange(pageParams.urlParams);
		activePageChange(1);
		switch(name) {
			case "status":
				statusChange(e.target.value);
				break;
			case "category_ids":
				categoryChange(e.target.value);
				break;
			case "has_no_working_day":
				hasNoWorkingDayChange(e.target.value);
				break;
			case "service_at_user_location":
				serviceAtUserLocationChange(e.target.value);
				break;
			case "search":
				searchChange(e.target.value);
				break;
			default:
				return null;
		}
	};

	const renderTooltipContent = (services) => {
		const _services =  services || [];
		let _tooltipContent = '';
		for(let i=0; i<_services.length; i++) {
			const s = _services[i];
			_tooltipContent = _tooltipContent + '<div>' + ((s.service && s.service.title && s.service.title.translations && s.service.title.translations['eng']) || 'N/A') + (i === _services.length - 1 ? '' : ', ') + '</div>';
		}
		return _tooltipContent;
	};

	const specialistsList = (specialists._embedded && specialists._embedded.specialist) || [];
	const page_size = specialists.page_size  || 25;
	const total_items = specialists.total_items  || 1;

	return (
		<div className="container-fluid">
			<div className="card shadow">
				<div className="card-header py-3">
					<div className="row">
						<div className="col-sm-12 col-md-12">
							<h1 className="h3 text-gray-800">Specialists</h1>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="form-group">
								<label>Search</label>
								<input type="text"
									   className="form-control"
									   id="title"
									   value={search}
									   onChange={e => handleFilterChange(e, 'search')} />
							</div>
						</div>
						<div className="col-sm-12 col-md-3">
							<div className="form-group">
								<label>Status</label>
								<select className="form-control" id="status" value={status} onChange={e => handleFilterChange(e, "status")}>
									<option key="-1" value="-1">Select</option>
									{config.FILTER_STATUSES.map(e => <option key={e.value} value={e.value}>{e.label}</option>)}
								</select>
							</div>
						</div>
						<div className="col-sm-12 col-md-3">
							<div className="form-group">
								<label>Category</label>
								<select className="form-control" id="category_ids" value={category_ids} onChange={e => handleFilterChange(e, "category_ids")}>
									<option key="-1" value="-1">Select</option>
									{businessCategoryList.map(e => <option key={e.id} value={e.id}>{e.title.text}</option>)}
								</select>
							</div>
						</div>
						<div className="col-sm-12 col-md-3">
							<div className="form-group">
								<label>Has No Working Day</label>
								<select className="form-control" id="has_no_working_day" value={has_no_working_day} onChange={e => handleFilterChange(e, "has_no_working_day")}>
									<option key="-1" value="-1">Select</option>
									<option key="0" value="true">Yes</option>
									<option key="1" value="false">No</option>
								</select>
							</div>
						</div>
						<div className="col-sm-12 col-md-3">
							<div className="form-group">
								<label>Service At User Location</label>
								<select className="form-control" id="service_at_user_location" value={service_at_user_location} onChange={e => handleFilterChange(e, "service_at_user_location")}>
									<option key="-1" value="-1">Select</option>
									<option key="0" value="true">Yes</option>
									<option key="1" value="false">No</option>
								</select>
							</div>
						</div>
					</div>
				</div>
				<div className="card-body">
					<div className="row">
						{loading ?
							<Loader /> :
							<div className="col-sm-12 dataTables_container">
								<div className="dataTables_wrapper">
									<table className="table table-bordered dataTable" id="dataTable" width="100%" cellSpacing="0">
										{specialistsList.length ?
											<Fragment>
												<thead>
													<tr role="row">
														<th className="col-name">Name</th>
														<th>Email</th>
														<th>Titles</th>
														<th>Branch</th>
														<th className="col-services">Services</th>
														<th className="col-phone">Phone</th>
														<th>Status</th>
														<th>Link</th>
														<th className="col-message-box">Note</th>
														<th className="col-date">Created</th>
													</tr>
												</thead>
												<tfoot>
													<tr>
														<th className="col-name">Name</th>
														<th>Email</th>
														<th>Titles</th>
														<th>Branch</th>
														<th className="col-services">Services</th>
														<th className="col-phone">Phone</th>
														<th>Status</th>
														<th>Link</th>
														<th className="col-message-box">Note</th>
														<th className="col-date">Created</th>
													</tr>
												</tfoot>
											</Fragment> : null
										}

										<tbody>
											{specialistsList.map(item => (
												<tr role="row" className="odd" key={item.id}>
													<td className="col-name">{(item.user && item.user.name) || 'N/A'}</td>
													<td>{(item.user && item.user.email) || 'N/A'}</td>
													<td>
														{
															item.titles && item.titles.length ?
																item.titles.map((t, i) => <span key={i}>{(t.translations && t.translations['eng']) || 'N/A' + (i === item.titles.length - 1 ? '' : ', ')}</span>) :
																'N/A'
														}
													</td>
													<td>{(item.branch && item.branch.name) || 'N/A'}</td>
													<td className="col-services"
														data-for={'tooltip-' + item.id}
														data-tip={renderTooltipContent(item.services)}
														data-class="specialists-services-tooltip"
														data-iscapture="true">
														{item.services && item.services.length ?
															<ReactTooltip
																id={'tooltip-' + item.id}
																place="left"
																type="info"
																effect="solid"
																html={true}
																multiline={true}
																/>: null
														}
														{
															item.services && item.services.length ?
																item.services.map((s, i) => <span key={i}>{((s.service && s.service.title && s.service.title.translations && s.service.title.translations['eng']) || 'N/A') + (i === item.services.length - 1 ? '' : ', ')}</span>) :
																'N/A'
														}
													</td>
													<td className="col-phone">
														{
															item.phone_numbers && item.phone_numbers.length ?
																item.phone_numbers.map((ph, i) => <span key={i}>{(ph.phone_number && ('+' + ph.phone_number.country_code + ' ' + ph.phone_number.number)) || 'N/A'}&nbsp;</span>) :
																'N/A'
														}
													</td>
													<td>{item.status || 'N/A'}</td>
													<td>
														<div className="copyClipboard" onClick={() => utils.copyCodeToClipboard('https://www.dobooki.com/app/?action=specialist_booking&user_id=' + item.user.id)}>
															<i className="fa fa-copy"></i>
														</div>
													</td>
													<td className="col-message-box">
														<MessageBox specialistId={item.id || null}/>
													</td>
													<td className="col-date">{item.created_at || 'N/A'}</td>
												</tr>
											))}
										</tbody>

										{specialistsList.length === 0 ?
											<tbody>
												<tr role="row" className="odd">
													<td colSpan="4">
														<div className="empty-list">List is empty</div>
													</td>
												</tr>
											</tbody> : null
										}
									</table>
								</div>
							</div>
						}
					</div>
					{loading || specialistsList.length === 0 ?
						null :
						<div className="row">
							<div className="col-sm-12 col-md-5"></div>
							<div className="col-sm-12 col-md-7">
								<div className="dataTables_paginate paging_simple_numbers">
									<Pagination
										innerClass="pagination float-right"
										itemClass="page-item"
										linkClass="page-link"
										activePage={activePage}
										itemsCountPerPage={page_size}
										totalItemsCount={total_items}
										pageRangeDisplayed={5}
										onChange={handlePageChange}
										/>
								</div>
							</div>
						</div>
					}
				</div>
			</div>
		</div>
	);
}

SpecialistsList.propTypes = {
	businessCategoryList: PropTypes.array,
	specialists: PropTypes.object,
	loading: PropTypes.bool,
	onGetSpecialistsList: PropTypes.func,
	onGetBusinessCategoryList: PropTypes.func
};

const mapStateToProps = state => {
	return {
		businessCategoryList: state.business.businessCategoryList,
		specialists: state.specialists.specialists,
		loading: state.specialists.isSpecialistsLoading
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetSpecialistsList: (urlParams, cursor, perPage) => dispatch(getSpecialistsList(urlParams, cursor, perPage)),
		onGetBusinessCategoryList: () => dispatch(getBusinessCategoryList())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SpecialistsList);