import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { createBanner, getSpecialistsAllList, getBranchesAllList } from '../../store/actions';
import PropTypes from 'prop-types';
import './Banner.css';

import Loader from '../../components/UI/Loader/Loader';

function Banner({
	branches,
	specialists,
	isLoading,
	onCreateBanner,
	onGetSpecialistsAllList,
	onGetBranchesAllList,
	isCreating,
	history}) {

	useEffect(() => {
		onGetSpecialistsAllList();
		onGetBranchesAllList();
	},
	[onGetSpecialistsAllList, onGetBranchesAllList]);

	const [specialistId, specialistChange] = useState(-1);
	const [title, titleChange] = useState('');
	const [titleTouched, titleTouchedChange] = useState(false);
	const [url, urlChange] = useState('');
	const [branchId, branchChange] = useState(-1);
	const [img, imgChange] = useState('');

	const handleFieldChange = (e, f, field) => {
		f(e.target.value);
		if(field === 'title') {
			titleTouchedChange(true);
		}
	};

	const handleFileUpload = e => {
		let file = e.target.files[0];
		if(file) {
			const reader = new FileReader();
			reader.onload = readerEvt => {
				var base64 = readerEvt.target.result;
				imgChange(base64);
			};
			reader.readAsDataURL(file);
		}
	};

	const handleSaveChanges = () => {
		const dataToSend = {image: img, title: title, url};
		if(specialistId != "-1") {
			dataToSend.specialist_id = specialistId
		}
		if(branchId != "-1") {
			dataToSend.branch_id = +branchId
		}
		onCreateBanner(dataToSend).then(response => {
			history.push('/banners');
		})
	};

	const disabled = !img || !title;

	return (
		<Fragment>
			{isLoading ?
				<div className="loader-wrapper">
					<Loader />
				</div> :
				<Fragment>
					<div className="container-fluid fields-wrapper">
						<div className="row">
							<div className="col-sm-12 col-md-6">
								<div className="form-group">
									<label>Title<span>*</span></label>
									<input type="text"
										   className={"form-control " + (titleTouched && title.length === 0 ? "field-error" : "")}
										   id="title"
										   value={title}
										   onChange={e => handleFieldChange(e, titleChange, 'title')} />
									{
										titleTouched && title.length === 0 ?
											<div className="form-control-errors-wrapper">
												<p>Title is required.</p>
											</div> :
											null
									}

								</div>
							</div>

							<div className="col-sm-12 col-md-6">
								<div className="form-group">
									<label>Specialists</label>
									<select className="form-control" id="status" value={specialistId} onChange={e => handleFieldChange(e, specialistChange)}>
										<option key={-1} value={-1}>Select</option>
										{(specialists || []).map(e => <option key={e.id} value={e.id}>{e.name}</option>)}
									</select>
								</div>
							</div>

							<div className="col-sm-12 col-md-6">
								<div className="form-group">
									<label>Url</label>
									<input type="text"
										   className="form-control"
										   id="url"
										   value={url}
										   onChange={e => handleFieldChange(e, urlChange, 'url')} />

								</div>
							</div>

							<div className="col-sm-12 col-md-6">
								<div className="form-group">
									<label>Branches</label>
									<select className="form-control" id="status" value={branchId} onChange={e => handleFieldChange(e, branchChange)}>
										<option key={-1} value={-1}>Select</option>
										{(branches || []).map(e => <option key={e.id} value={e.id}>{e.name}</option>)}
									</select>
								</div>
							</div>

							<div className="col-lg-12">
								<input type="file"
									   className="form-control"
									   id="file"
									   name="image"
									   accept=".jpg, .jpeg, .png"
									   onChange={handleFileUpload} />
								<label htmlFor="file">choose a file</label>
							</div>
						</div>
						{img.length ?
							<div className="row">
								<div className="col-lg-12">
									<img className="img-fluid uploading-img" src={img} alt="" />
								</div>
							</div> : null
						}
					</div>

					<div className="container-fluid">
						<div className="row">
							<div className="col-lg-12">
								<button type="submit"
										className="btn btn-primary mb-2 float-left"
										onClick={() => history.push('/banners')}>Back</button>
								<button type="submit"
										disabled={disabled || isCreating}
										className="btn btn-primary mb-2 float-right"
										onClick={handleSaveChanges}>Save</button>
							</div>
						</div>
					</div>

				</Fragment>
			}
		</Fragment>

	);
}

Banner.propTypes = {
	specialists: PropTypes.array,
	branches: PropTypes.array,
	isLoading: PropTypes.bool,
	isCreating: PropTypes.bool,
	onCreateBanner: PropTypes.func,
	onGetSpecialistsAllList: PropTypes.func,
	onGetBranchesAllList: PropTypes.func
};

const mapStateToProps = state => {
	return {
		specialists: state.specialists.specialistsAll,
		branches: state.branches.branchesAll,
		isLoading: state.specialists.isSpecialistsAllLoading || state.branches.isBranchesAllLoading,
		isCreating: state.banners.isBannerCreating
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onCreateBanner: data => dispatch(createBanner(data)),
		onGetSpecialistsAllList: () => dispatch(getSpecialistsAllList()),
		onGetBranchesAllList: () => dispatch(getBranchesAllList())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Banner);