import API from '../../services/api/api';
import { toast } from 'react-toastify';
import utils from '../../services/utils';

import {
	GET_BANNERS_LIST_START,
	GET_BANNERS_LIST_SUCCESS,
	GET_BANNERS_LIST_FAIL,

	GET_BANNER_BY_ID_START,
	GET_BANNER_BY_ID_SUCCESS,
	GET_BANNER_BY_ID_FAIL,

	CREATE_BANNER_START,
	CREATE_BANNER_SUCCESS,
	CREATE_BANNER_FAIL,

	EDIT_BANNER_START,
	EDIT_BANNER_SUCCESS,
	EDIT_BANNER_FAIL,

	UPDATE_USER_DATA
} from './actionTypes';

/* bannersList start */
const getBannersListStart = () => {
	return {
		type: GET_BANNERS_LIST_START
	};
};

const getBannersListSuccess = bannersList => {
	return {
		type: GET_BANNERS_LIST_SUCCESS,
		bannersList
	};
};

const getBannersListFail = () => {
	return {
		type: GET_BANNERS_LIST_FAIL
	};
};

export const getBannersList = () => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(getBannersListStart());
		API.getBannersList(userData.access_token)
			.then(response => {
				const bannersList = response.data;
				dispatch(getBannersListSuccess(bannersList));
			})
			.catch( error => {
				dispatch(getBannersListFail());
				console.log(error);
				if(error.response && error.response.status === 401) {
					toast.error(((error.response && error.response.statusText) || 'Something went wrong'), {
						position: toast.POSITION.TOP_RIGHT
					});
					utils.deleteDataFromStorage('TUK_TUK_USER_DATA');
					dispatch({
						type: UPDATE_USER_DATA,
						user: {}
					});
				}
			});

	};
};
/* bannersList end */

/* get banner by id start */
const getBannerByIdStart = () => {
	return {
		type: GET_BANNER_BY_ID_START
	};
};

const getBannerByIdSuccess = banner => {
	return {
		type: GET_BANNER_BY_ID_SUCCESS,
		banner
	};
};

const getBannerByIdFail = () => {
	return {
		type: GET_BANNER_BY_ID_FAIL
	};
};

export const getBannerById = id => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(getBannerByIdStart());
		return API.getBannerById(id, userData.access_token)
			.then(response => {
				const bannerList = response.data || [];
				const banner = bannerList.filter(b => (b.id + '') === id);
				if(banner && banner[0]) {
					dispatch(getBannerByIdSuccess(banner[0]));
					return banner[0];
				} else {
					dispatch(getBannerByIdFail());
					toast.error(('Something went wrong fetching data'), {
						position: toast.POSITION.TOP_RIGHT
					});
					return {};
				}
			})
			.catch( error => {
				dispatch(getBannerByIdFail());
				console.log(error);
				if(error.response && error.response.status === 401) {
					toast.error(((error.response && error.response.statusText) || 'Something went wrong'), {
						position: toast.POSITION.TOP_RIGHT
					});
					utils.deleteDataFromStorage('TUK_TUK_USER_DATA');
					dispatch({
						type: UPDATE_USER_DATA,
						user: {}
					});
				}
				return {};
			});

	};
};
/* get banner by id end */

/* create banner */
const createBannerStart = () => {
	return {
		type: CREATE_BANNER_START
	};
};

const createBannerSuccess = () => {
	return {
		type: CREATE_BANNER_SUCCESS
	};
};

const createBannerFail = () => {
	return {
		type: CREATE_BANNER_FAIL
	};
};

export const createBanner = data => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(createBannerStart());
		return API.createBanner(userData.access_token, data)
			.then(response => {
				dispatch(createBannerSuccess());
				toast.success('Banner created successfully', {
					position: toast.POSITION.TOP_RIGHT
				});
				return response;
			})
			.catch( error => {
				dispatch(createBannerFail());
				console.log(error.response);
				if(error.response && error.response.status === 401) {
					toast.error(((error.response && error.response.statusText) || 'Something went wrong'), {
						position: toast.POSITION.TOP_RIGHT
					});
					utils.deleteDataFromStorage('TUK_TUK_USER_DATA');
					dispatch({
						type: UPDATE_USER_DATA,
						user: {}
					});
				} else if(error.response && error.response.data && error.response.data.detail) {
					toast.error(((error.response.data.show_this) || 'Something went wrong'), {
						position: toast.POSITION.TOP_RIGHT
					});
				}
				return {};
			});

	};
};
/* create banner end */

/* edit banner */
const editBannerStart = () => {
	return {
		type: CREATE_BANNER_START
	};
};

const editBannerSuccess = () => {
	return {
		type: CREATE_BANNER_SUCCESS
	};
};

const editBannerFail = () => {
	return {
		type: CREATE_BANNER_FAIL
	};
};

export const editBannerById = (data, id) => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(editBannerStart());
		return API.editBannerById(userData.access_token, data, id)
			.then(response => {
				dispatch(editBannerSuccess());
				toast.success('Banner edited successfully', {
					position: toast.POSITION.TOP_RIGHT
				});
				return response;
			})
			.catch( error => {
				dispatch(editBannerFail());
				console.log(error.response);
				if(error.response && error.response.status === 401) {
					toast.error(((error.response && error.response.statusText) || 'Something went wrong'), {
						position: toast.POSITION.TOP_RIGHT
					});
					utils.deleteDataFromStorage('TUK_TUK_USER_DATA');
					dispatch({
						type: UPDATE_USER_DATA,
						user: {}
					});
				} else if(error.response && error.response.data && error.response.data.detail) {
					toast.error(((error.response.data.show_this) || 'Something went wrong'), {
						position: toast.POSITION.TOP_RIGHT
					});
				}
				return error.response;
			});

	};
};
/* edit banner end */