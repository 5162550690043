import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

import { updateUser, logout } from './store/actions';
import utils from './services/utils';
import Navigation from './components/Navigation/Navigation';
import Toolbar from './components/Toolbar/Toolbar';
import Footer from './components/Footer/Footer';
import Login from './containers/Login/Login';
import BusinessCategoryList from './containers/BusinessCategoryList/BusinessCategoryList';
import BusinessCategory from './containers/BusinessCategory/BusinessCategory';
import BusinessServiceList from './containers/BusinessServiceList/BusinessServiceList';
import BusinessService from './containers/BusinessService/BusinessService';
import SpecialistsList from './containers/SpecialistsList/SpecialistsList';
import BranchesList from './containers/BranchesList/BranchesList';
import BannersList from './containers/BannersList/BannersList';
import Banner from './containers/Banner/Banner';
import BannerNew from './containers/Banner/BannerNew';
import CreateNotifications from './containers/CreateNotifications/CreateNotifications';

function App({ user, onUpdateUser, onLogout }) {
	const userData = utils.getDataFromStorage('TUK_TUK_USER_DATA');
	const isAuthenticated = (user && user.access_token) || (userData && userData.access_token);

	if(userData && userData.access_token && !user.access_token) {
		onUpdateUser(userData);
	}

	return (
		<React.Fragment>
		  	{isAuthenticated ?
			  	<React.Fragment>
				  	<Navigation />
				  	<div className="content-wrapper">
					  	<Toolbar onLogout={onLogout} />
					  	<Switch>
						  	<Route exact path="/business-categories" component={BusinessCategoryList} />
						  	<Route exact path="/business-category/:id" component={BusinessCategory} />
							<Route exact path="/business-services" component={BusinessServiceList} />
							<Route exact path="/business-service/:id" component={BusinessService} />
							<Route exact path="/specialists" component={SpecialistsList} />
							<Route exact path="/branches" component={BranchesList} />
							<Route exact path="/banners" component={BannersList} />
							<Route exact path="/banner/new" component={BannerNew} />
							<Route exact path="/banner/:id" component={Banner} />
							<Route exact path="/notifications/create" component={CreateNotifications} />
						  	<Redirect to="/specialists" />
					  	</Switch>
				  	</div>
				  	<Footer />
			  	</React.Fragment> :
			  	<Switch>
				  	<Route exact path="/login" component={Login} />
				  	<Redirect to="/login" />
			  	</Switch>
		  	}
		  	<ToastContainer />
		</React.Fragment>
	);
}

const mapStateToProps = state => {
  return {
	user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
	return {
		onUpdateUser: user => dispatch(updateUser(user)),
		onLogout: user => dispatch(logout())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
