import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { createNotification, getUsersList } from '../../store/actions';
import PropTypes from 'prop-types';
import './CreateNotifications.css';

function CreateNotifications({
		isCreating,
		usersList,
		onGetUsersList,
		onCreateNotification
	}) {
	const [type, typeChange] = useState('email');
	const [isSocialRegistration, isSocialRegistrationChange] = useState(false);
	const [userIds, userIdsChange] = useState([]);
	const [userIdIsTouched, userIdTouchedChange] = useState(false);
	const [subject, subjectChange] = useState('');
	const [subjectIsTouched, subjectTouchedChange] = useState(false);
	const [notification, notificationChange] = useState('');
	const [notificationIsTouched, notificationTouchedChange] = useState(false);

	useEffect(() => {
		onGetUsersList(isSocialRegistration ? ('is_social_registration=' + isSocialRegistration) : '');
	},
	[onGetUsersList, isSocialRegistration]);

	const handleMultiSelectChange = (e) => {
		userIdsChange(e);
		userIdTouchedChange(true);
	};

	const handleFieldChange = (e, f) => {
		f(e.target.value);
	};

	const handleTouchedChange = (e, f) => {
		f(true);
	};

	const handleCreateNotification = () => {
		const notificationArray = [];
		const cellData = {
			type,
			details: {}
		};
		if(type === "email") {
			cellData.details = {
				email_subject: subject,
				email_body: notification
			}
		} else if(type === "sms") {
			cellData.details = {
				sms_text: notification
			}
		} else {
			cellData.details = {
				push_notification_title: subject,
				push_notification_text: notification
			}
		}
		for(let i=0; i<userIds.length; i++) {
			const _cellData = {...cellData};
			_cellData['user_id'] = userIds[i].value;
			notificationArray.push(_cellData);
		}
		onCreateNotification(notificationArray).then(resp => {
			console.log(resp);
			typeChange('email');
			isSocialRegistrationChange(false);
			userIdsChange([]);
			userIdTouchedChange(false);
			subjectChange('');
			subjectTouchedChange(false);
			notificationChange('');
			notificationTouchedChange(false);
		});
	};

	const _usersList = usersList.map((i)=> {
		return { label: (i.first_name + ' ' + i.last_name), value: i.id }
	});
	const disabled = ((type === "email" || type === "push_notification") && subject.length === 0) || notification.length === 0 || userIds.length === 0;

	return (
		<Fragment>
			<div className="container-fluid fields-wrapper">
				<div className="mb-4">
					<h1 className="h3 mb-2 text-gray-800">Notifications</h1>
				</div>

				<div className="row">
					<div className="col-sm-12 col-md-12 multi-select-container">
						<div className="form-group multi-select">
							<label>User<span>*</span></label>
							<Select
								isMulti
								name="colors"
								options={_usersList}
								value={userIds}
								onChange={handleMultiSelectChange}
								className="basic-multi-select"
								classNamePrefix="select"
								/>
							{ userIdIsTouched && (!userIds || userIds.length === 0) ?
								<div className="form-control-errors-wrapper">
									<p>This field is required.</p>
								</div> :
								null
							}
						</div>

						<div className="form-group multi-select-social-filter">
							<div className="custom-control custom-checkbox small socialRegistrationCheckbox">
								<input type="checkbox"
									   className="custom-control-input"
									   id="socialRegistrationCheckbox"
									   checked={isSocialRegistration}
									   onChange={() => isSocialRegistrationChange(!isSocialRegistration)} />
								<label className="custom-control-label" htmlFor="socialRegistrationCheckbox">Social Registered</label>
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-sm-12 col-md-6">
						<div className="form-group">
							<label>Type<span>*</span></label>
							<select className="form-control" id="type" value={type} onChange={e => handleFieldChange(e, typeChange)}>
								<option key="email" value="email">Email</option>
								<option key="sms" value="sms">SMS</option>
								<option key="push_notification" value="push_notification">Push</option>
							</select>
						</div>
					</div>
				</div>

				<div className="row">
					 {(type === "email" || type === "push_notification") ?
						 <div className="col-sm-12 col-md-6">
							 <div className="form-group">
								<label>{type === "email" ? 'Subject' : type === "push_notification" ? 'Title' : ''}<span>*</span></label>
								 <input type="text"
										className={"form-control " + (subjectIsTouched && subject.length === 0 ? "field-error" : "")}
										id="subject"
										name="subject"
										value={subject}
										placeholder={type === "email" ? 'Subject' : type === "push_notification" ? 'Title' : ''}
										onChange={e => handleFieldChange(e, subjectChange)}
										onBlur={e => handleTouchedChange(e, subjectTouchedChange)} />
							 { subjectIsTouched && subject.length === 0 ?
								 <div className="form-control-errors-wrapper">
									<p>This field is required.</p>
								 </div> :
								 null
							 }

							 </div>
						 </div> : null
					 }

					 <div className="col-lg-12">
						<div className="form-group">
							<label>Notification<span>*</span></label>
							<textarea className={"form-control " + (notificationIsTouched && notification.length === 0 ? "field-error" : "")}
									  id="notification"
									  name="notification"
									  value={notification}
									  onChange={e => handleFieldChange(e, notificationChange)}
									  onBlur={e => handleTouchedChange(e, notificationTouchedChange)} />
							{ notificationIsTouched && notification.length === 0 ?
								<div className="form-control-errors-wrapper">
									<p>This field is required.</p>
								</div> :
								null
							}

						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid">
				<div className="row">
					<div className="col-lg-12">
						<button type="submit"
								disabled={isCreating || disabled}
								className="btn btn-primary mb-2 float-right"
								onClick={handleCreateNotification}>Create</button>
					</div>
				</div>
			</div>
		</Fragment>

	);
}

CreateNotifications.propTypes = {
	isCreating: PropTypes.bool,
	usersList: PropTypes.array,
	onGetUsersList: PropTypes.func,
	onCreateNotification: PropTypes.func
};

const mapStateToProps = state => {
	return {
		isCreating: state.notifications.isNotificationCreating,
		usersList: state.main.usersList
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetUsersList: urlParams => dispatch(getUsersList(urlParams)),
		onCreateNotification: data => dispatch(createNotification(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNotifications);