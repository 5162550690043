import React, {useEffect, Fragment} from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { getBannersList } from '../../store/actions';
import PropTypes from 'prop-types';
import './BannersList.css';

import Loader from '../../components/UI/Loader/Loader';

function BannersList({bannersList, loading, onGetBannersList}) {
	useEffect(() => onGetBannersList(),
		[onGetBannersList]);

	return (
		<div className="container-fluid banners-list">
			<div className="card shadow">
				<div className="card-header py-3">
					<h1 className="h3 text-gray-800">Banners</h1>
					<NavLink exact to="/banner/new" className="btn btn-primary btn-circle">
						<i className="fas fa-plus"></i>
					</NavLink>
				</div>
				<div className="card-body">
					<div className="row">
						{loading ?
							<Loader /> :
							<Fragment>
								{bannersList.length ?
									<Fragment>
										{bannersList.map(item => (
											<div key={item.id} className="col-sm-6 col-xs-12">
												<div className="shadow text-center banner-item-container mb-3">
													<div className="banner-wrapper px-3 px-sm-4 mt-4 mb-4">
														<NavLink exact to={`/banner/${item.id}`} className="banner-edit-icon btn btn-success btn-circle btn-sm">
															<i className="fas fa-edit"></i>
														</NavLink>
														<div className="banner-itm-image-container"
															 style={{backgroundImage: `url(${item.image_url})`}}></div>
													</div>
													<h5 className="m-0 font-weight-bold text-primary">{item.title}</h5>
													{item.specialist && item.specialist.user && item.specialist.user.name ?
														<p className="py-3 mb-0">{item.specialist.user.name}</p> : null
													}
												</div>
											</div>
										))}
									</Fragment> : null
								}
							</Fragment>
						}
					</div>
				</div>
			</div>
		</div>
	);
}

BannersList.propTypes = {
	bannersList: PropTypes.array,
	loading: PropTypes.bool,
	onGetBannersList: PropTypes.func
};

const mapStateToProps = state => {
	return {
		bannersList: state.banners.bannersList,
		loading: state.banners.isBannersLoading
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetBannersList: () => dispatch(getBannersList())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BannersList);