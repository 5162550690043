import React from 'react';
import moment from 'moment';

import './Footer.css';

const footer = () => {
    return (
        <footer className="sticky-footer bg-white">
            <div className="container my-auto">
                <div className="copyright text-center my-auto">
                    <span>Copyright &copy; TukTuk { moment().format('YYYY') }</span>
                </div>
            </div>
        </footer>
    );
};


export default footer;
