import API from '../../services/api/api';
import { toast } from 'react-toastify';
import utils from '../../services/utils';

import {
	CREATE_NOTIFICATION_START,
	CREATE_NOTIFICATION_SUCCESS,
	CREATE_NOTIFICATION_FAIL,

	UPDATE_USER_DATA
} from './actionTypes';

/* create notification start */
export const createNotificationStart = () => {
	return {
		type: CREATE_NOTIFICATION_START
	};
};

export const createNotificationSuccess = () => {
	return {
		type: CREATE_NOTIFICATION_SUCCESS
	};
};

export const createNotificationFail = () => {
	return {
		type: CREATE_NOTIFICATION_FAIL
	};
};

export const createNotification = data => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(createNotificationStart());
			return API.createNotification(userData.access_token, data)
				.then(response => {
					dispatch(createNotificationSuccess());
					toast.success('Notification created successfully', {
						position: toast.POSITION.TOP_RIGHT
					});
					return response;
				})
				.catch( error => {
					dispatch(createNotificationFail());
					if(error.response) {
						if(error.response.status === 401) {
							utils.deleteDataFromStorage('TUK_TUK_USER_DATA');
							dispatch({
								type: UPDATE_USER_DATA,
								user: {}
							});
						} else {
							toast.error(((error.response && error.response.statusText) || 'Something went wrong'), {
								position: toast.POSITION.TOP_RIGHT
							});
						}
					}
					return error.response;
				});

	};
};
/* create notification end */