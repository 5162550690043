import React, {useEffect, useState, Fragment} from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { getBusinessCategoryList, orderBusinessCategoryList } from '../../store/actions';
import utils from '../../services/utils';
import config from '../../services/config';
import PropTypes from 'prop-types';
import './BusinessCategoryList.css';
import {ReactSortable} from 'react-sortablejs';

import Loader from '../../components/UI/Loader/Loader';

function BusinessCategoryList({
		businessCategoryList,
		loading,
		onGetBusinessCategoryList,
		onOrderBusinessCategoryList
	}) {
	const [urlParams, urlParamsChange] = useState('');
	const [status, statusChange] = useState('-1');
	useEffect(() => onGetBusinessCategoryList(null),
		[onGetBusinessCategoryList]);

	useEffect(() => {
		const pageParams = utils.processUrlParams(urlParams);

		onGetBusinessCategoryList(pageParams.urlParams, null);
	}, [onGetBusinessCategoryList, urlParams]);

	const handleFilterChange = (e, name) => {
		const pageParams = utils.processUrlParams(urlParams, 1, {[name]: e.target.value});

		urlParamsChange(pageParams.urlParams);
		switch(name) {
			case "status":
				statusChange(e.target.value);
				break;
			default:
				return null;
		}
	};

	useEffect(() => onBusinessCategoriesChange(businessCategoryList.sort((a, b) => (a.order - b.order))),
		[businessCategoryList]);

	const [businessCategories, onBusinessCategoriesChange] = useState([]);

	const onDragItem = list => {
		onBusinessCategoriesChange(list);
	};

	const onDragEnd = e => {
		const orderedList = businessCategories.map((e, i) => ({id: e.id, order: i + 1}));
		onOrderBusinessCategoryList(orderedList);
		console.log(orderedList);
	};

	return (
		<div className="container-fluid">
			<div className="card shadow">
				<div className="card-header py-3">
					<div className="row">
						<div className="col-sm-12 col-md-9">
							<h1 className="h3 text-gray-800">Business Categories</h1>
						</div>
						<div className="col-sm-12 col-md-3">
							<div className="form-group">
								<label>Status</label>
								<select className="form-control" id="status" value={status} onChange={e => handleFilterChange(e, "status")}>
									<option key="-1" value="-1">Select</option>
									{config.FILTER_STATUSES.map(e => <option key={e.value} value={e.value}>{e.label}</option>)}
								</select>
							</div>
						</div>
					</div>
				</div>
				<div className="card-body">
					<div className="row">
						{loading ?
							<Loader /> :
							<div className="col-sm-12 dataTables_container">
								<div className="dataTables_wrapper">
									<table className="table table-bordered dataTable" id="dataTable" width="100%" cellSpacing="0">
										{businessCategories.length ?
											<Fragment>
												<thead>
													<tr role="row">
														<th>Name</th>
														<th>Status</th>
														<th className="created-at">Created</th>
													</tr>
												</thead>
												<tfoot>
													<tr>
														<th>Name</th>
														<th>Status</th>
														<th>Created</th>
													</tr>
												</tfoot>
											</Fragment> : null
										}

										<ReactSortable tag="tbody" list={businessCategories} setList={onDragItem} onEnd={onDragEnd}>
											{businessCategories.map(item => (
												<tr role="row" className="odd" key={item.id}>
													<td><NavLink exact to={`/business-category/${item.id}`}>{(item.title && item.title.translations && item.title.translations['eng']) || 'N/A'}</NavLink></td>
													<td>{item.status || 'N/A'}</td>
													<td>{item.created_at || 'N/A'}</td>
												</tr>
											))}
										</ReactSortable>

										{businessCategories.length === 0 ?
											<tbody>
												<tr role="row" className="odd">
													<td colSpan="4">
														<div className="empty-list">List is empty</div>
													</td>
												</tr>
											</tbody> : null
										}
									</table>
								</div>
							</div>
						}
					</div>
				</div>
			</div>
		</div>
	);
}

BusinessCategoryList.propTypes = {
	businessCategoryList: PropTypes.array,
	loading: PropTypes.bool,
	onGetBusinessCategoryList: PropTypes.func,
	onOrderBusinessCategoryList: PropTypes.func
};

const mapStateToProps = state => {
	return {
		businessCategoryList: state.business.businessCategoryList,
		loading: state.business.loading
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetBusinessCategoryList: (urlParams) => dispatch(getBusinessCategoryList(urlParams)),
		onOrderBusinessCategoryList: list => dispatch(orderBusinessCategoryList(list))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessCategoryList);