import {
	GET_USERS_LIST_START,
	GET_USERS_LIST_SUCCESS,
	GET_USERS_LIST_FAIL
} from '../actions/actionTypes';
import utils from '../../services/utils';

const initialState = {
	usersList: [],
	isUsersListLoading: false
};

/* usersList start */
const getUsersListStart = (state, action) => {
	return utils.updateObject(state, {
		isUsersListLoading: true
	});
};

const getUsersListSuccess = (state, action) => {
	return utils.updateObject(state, {
		usersList: action.usersList,
		isUsersListLoading: false
	});
};

const getUsersListFail = (state, action) => {
	return utils.updateObject(state, {
		usersList: [],
		isUsersListLoading: false
	});
};
/* usersList end */

const specialistsReducer = (state = initialState, action = {}) => {
	switch (action.type) {
		case GET_USERS_LIST_START: return getUsersListStart(state, action);
		case GET_USERS_LIST_SUCCESS: return getUsersListSuccess(state, action);
		case GET_USERS_LIST_FAIL: return getUsersListFail(state, action);

		default:
			return state;
	}
};

export default specialistsReducer;