import {
	GET_BUSINESS_CATEGORY_LIST_START,
	GET_BUSINESS_CATEGORY_LIST_SUCCESS,
	GET_BUSINESS_CATEGORY_LIST_FAIL,

	GET_BUSINESS_CATEGORY_BY_ID_START,
	GET_BUSINESS_CATEGORY_BY_ID_SUCCESS,
	GET_BUSINESS_CATEGORY_BY_ID_FAIL,

	EDIT_BUSINESS_CATEGORY_BY_ID_START,
	EDIT_BUSINESS_CATEGORY_BY_ID_SUCCESS,
	EDIT_BUSINESS_CATEGORY_BY_ID_FAIL,

	ORDER_BUSINESS_CATEGORY_LIST_START,
	ORDER_BUSINESS_CATEGORY_LIST_SUCCESS,
	ORDER_BUSINESS_CATEGORY_LIST_FAIL,

	GET_BUSINESS_SERVICE_LIST_START,
	GET_BUSINESS_SERVICE_LIST_SUCCESS,
	GET_BUSINESS_SERVICE_LIST_FAIL,

	GET_BUSINESS_SERVICE_BY_ID_START,
	GET_BUSINESS_SERVICE_BY_ID_SUCCESS,
	GET_BUSINESS_SERVICE_BY_ID_FAIL,

	EDIT_BUSINESS_SERVICE_BY_ID_START,
	EDIT_BUSINESS_SERVICE_BY_ID_SUCCESS,
	EDIT_BUSINESS_SERVICE_BY_ID_FAIL,
} from '../actions/actionTypes';
import utils from '../../services/utils';

const initialState = {
	businessCategoryList: [],
	loading: false,
	businessCategory: {},
	businessCategoryLoading: false,
	isBusinessCategoryEditing: false,
	isBusinessCategoryListOrdering: false,
	businessServices: {},
	isServiceLoading: false,
	businessService: {},
	businessServiceLoading: false,
	isBusinessServiceEditing: false
};

/* business category list start */
const getBusinessCategoryListStart = (state, action) => {
	return utils.updateObject(state, {
		loading: true
	});
};

const getBusinessCategoryListSuccess = (state, action) => {
	return utils.updateObject(state, {
		businessCategoryList: action.businessCategoryList,
		loading: false
	});
};

const getBusinessCategoryListFail = (state, action) => {
	return utils.updateObject(state, {
		businessCategoryList: [],
		loading: false
	});
};
/* business category list end */


/* get business category by id start */
const getBusinessCategoryByIdStart = (state, action) => {
	return utils.updateObject(state, {
		businessCategoryLoading: true
	});
};

const getBusinessCategoryByIdSuccess = (state, action) => {
	return utils.updateObject(state, {
		businessCategory: action.businessCategory,
		businessCategoryLoading: false
	});
};

const getBusinessCategoryByIdFail = (state, action) => {
	return utils.updateObject(state, {
		businessCategory: {},
		businessCategoryLoading: false
	});
};
/* get business category by id end */

/* edit business category by id start */
const editBusinessCategoryByIdStart = (state, action) => {
	return utils.updateObject(state, {
		isBusinessCategoryEditing: true
	});
};

const editBusinessCategoryByIdSuccess = (state, action) => {
	return utils.updateObject(state, {
		isBusinessCategoryEditing: false
	});
};

const editBusinessCategoryByIdFail = (state, action) => {
	return utils.updateObject(state, {
		isBusinessCategoryEditing: false
	});
};
/* edit business category by id end */

/* order business category list start */
const orderBusinessCategoryListStart = (state, action) => {
	return utils.updateObject(state, {
		isBusinessCategoryListOrdering: true
	});
};

const orderBusinessCategoryListSuccess = (state, action) => {
	return utils.updateObject(state, {
		isBusinessCategoryListOrdering: false
	});
};

const orderBusinessCategoryListFail = (state, action) => {
	return utils.updateObject(state, {
		isBusinessCategoryListOrdering: false
	});
};
/* order business category list end */

/* business service list start */
const getBusinessServiceListStart = (state, action) => {
	return utils.updateObject(state, {
		isServiceLoading: true
	});
};

const getBusinessServiceListSuccess = (state, action) => {
	return utils.updateObject(state, {
		businessServices: action.businessServices,
		isServiceLoading: false
	});
};

const getBusinessServiceListFail = (state, action) => {
	return utils.updateObject(state, {
		businessServices: {},
		isServiceLoading: false
	});
};
/* business service list end */

/* get business service by id start */
const getBusinessServiceByIdStart = (state, action) => {
	return utils.updateObject(state, {
		businessServiceLoading: true
	});
};

const getBusinessServiceByIdSuccess = (state, action) => {
	return utils.updateObject(state, {
		businessService: action.businessService,
		businessServiceLoading: false
	});
};

const getBusinessServiceByIdFail = (state, action) => {
	return utils.updateObject(state, {
		businessService: {},
		businessServiceLoading: false
	});
};
/* get business service by id end */

/* edit business service by id start */
const editBusinessServiceByIdStart = (state, action) => {
	return utils.updateObject(state, {
		isBusinessServiceEditing: true
	});
};

const editBusinessServiceByIdSuccess = (state, action) => {
	return utils.updateObject(state, {
		isBusinessServiceEditing: false
	});
};

const editBusinessServiceByIdFail = (state, action) => {
	return utils.updateObject(state, {
		isBusinessServiceEditing: false
	});
};
/* edit business service by id end */

const businessReducer = (state = initialState, action = {}) => {
	switch (action.type) {
		case GET_BUSINESS_CATEGORY_LIST_START: return getBusinessCategoryListStart(state, action);
		case GET_BUSINESS_CATEGORY_LIST_SUCCESS: return getBusinessCategoryListSuccess(state, action);
		case GET_BUSINESS_CATEGORY_LIST_FAIL: return getBusinessCategoryListFail(state, action);

		case GET_BUSINESS_CATEGORY_BY_ID_START: return getBusinessCategoryByIdStart(state, action);
		case GET_BUSINESS_CATEGORY_BY_ID_SUCCESS: return getBusinessCategoryByIdSuccess(state, action);
		case GET_BUSINESS_CATEGORY_BY_ID_FAIL: return getBusinessCategoryByIdFail(state, action);

		case EDIT_BUSINESS_CATEGORY_BY_ID_START: return editBusinessCategoryByIdStart(state, action);
		case EDIT_BUSINESS_CATEGORY_BY_ID_SUCCESS: return editBusinessCategoryByIdSuccess(state, action);
		case EDIT_BUSINESS_CATEGORY_BY_ID_FAIL: return editBusinessCategoryByIdFail(state, action);

		case ORDER_BUSINESS_CATEGORY_LIST_START: return orderBusinessCategoryListStart(state, action);
		case ORDER_BUSINESS_CATEGORY_LIST_SUCCESS: return orderBusinessCategoryListSuccess(state, action);
		case ORDER_BUSINESS_CATEGORY_LIST_FAIL: return orderBusinessCategoryListFail(state, action);

		case GET_BUSINESS_SERVICE_LIST_START: return getBusinessServiceListStart(state, action);
		case GET_BUSINESS_SERVICE_LIST_SUCCESS: return getBusinessServiceListSuccess(state, action);
		case GET_BUSINESS_SERVICE_LIST_FAIL: return getBusinessServiceListFail(state, action);

		case GET_BUSINESS_SERVICE_BY_ID_START: return getBusinessServiceByIdStart(state, action);
		case GET_BUSINESS_SERVICE_BY_ID_SUCCESS: return getBusinessServiceByIdSuccess(state, action);
		case GET_BUSINESS_SERVICE_BY_ID_FAIL: return getBusinessServiceByIdFail(state, action);

		case EDIT_BUSINESS_SERVICE_BY_ID_START: return editBusinessServiceByIdStart(state, action);
		case EDIT_BUSINESS_SERVICE_BY_ID_SUCCESS: return editBusinessServiceByIdSuccess(state, action);
		case EDIT_BUSINESS_SERVICE_BY_ID_FAIL: return editBusinessServiceByIdFail(state, action);

		default:
			return state;
	}
};

export default businessReducer;