import {
	GET_BANNERS_LIST_START,
	GET_BANNERS_LIST_SUCCESS,
	GET_BANNERS_LIST_FAIL,

	GET_BANNER_BY_ID_START,
	GET_BANNER_BY_ID_SUCCESS,
	GET_BANNER_BY_ID_FAIL,

	CREATE_BANNER_START,
	CREATE_BANNER_SUCCESS,
	CREATE_BANNER_FAIL,

	EDIT_BANNER_START,
	EDIT_BANNER_SUCCESS,
	EDIT_BANNER_FAIL
} from '../actions/actionTypes';
import utils from '../../services/utils';

const initialState = {
	bannersList: [],
	isBannersLoading: false,
	banner: {},
	isBannerLoading: false,
	isBannerCreating: false,
	isBannerEditing: false
};

/* bannersList start */
const getBannersListStart = (state, action) => {
	return utils.updateObject(state, {
		isBannersLoading: true
	});
};

const getBannersListSuccess = (state, action) => {
	return utils.updateObject(state, {
		bannersList: action.bannersList,
		isBannersLoading: false
	});
};

const getBannersListFail = (state, action) => {
	return utils.updateObject(state, {
		bannersList: [],
		isBannersLoading: false
	});
};
/* bannersList end */

/* get banner by id start */
const getBannerByIdStart = (state, action) => {
	return utils.updateObject(state, {
		isBannerLoading: true
	});
};

const getBannerByIdSuccess = (state, action) => {
	return utils.updateObject(state, {
		banner: action.banner,
		isBannerLoading: false
	});
};

const getBannerByIdFail = (state, action) => {
	return utils.updateObject(state, {
		banner: {},
		isBannerLoading: false
	});
};
/* get banner by id end */

/* create banner start */
const createBannerStart = (state, action) => {
	return utils.updateObject(state, {
		isBannerCreating: true
	});
};

const createBannerSuccess = (state, action) => {
	return utils.updateObject(state, {
		isBannerCreating: false
	});
};

const createBannerFail = (state, action) => {
	return utils.updateObject(state, {
		isBannerCreating: false
	});
};
/* create banner end */

/* edit banner start */
const editBannerStart = (state, action) => {
	return utils.updateObject(state, {
		isBannerEditing: true
	});
};

const editBannerSuccess = (state, action) => {
	return utils.updateObject(state, {
		isBannerEditing: false
	});
};

const editBannerFail = (state, action) => {
	return utils.updateObject(state, {
		isBannerEditing: false
	});
};
/* edit banner end */

const bannersReducer = (state = initialState, action = {}) => {
	switch (action.type) {
		case GET_BANNERS_LIST_START: return getBannersListStart(state, action);
		case GET_BANNERS_LIST_SUCCESS: return getBannersListSuccess(state, action);
		case GET_BANNERS_LIST_FAIL: return getBannersListFail(state, action);

		case GET_BANNER_BY_ID_START: return getBannerByIdStart(state, action);
		case GET_BANNER_BY_ID_SUCCESS: return getBannerByIdSuccess(state, action);
		case GET_BANNER_BY_ID_FAIL: return getBannerByIdFail(state, action);

		case CREATE_BANNER_START: return createBannerStart(state, action);
		case CREATE_BANNER_SUCCESS: return createBannerSuccess(state, action);
		case CREATE_BANNER_FAIL: return createBannerFail(state, action);

		case EDIT_BANNER_START: return editBannerStart(state, action);
		case EDIT_BANNER_SUCCESS: return editBannerSuccess(state, action);
		case EDIT_BANNER_FAIL: return editBannerFail(state, action);

		default:
			return state;
	}
};

export default bannersReducer;