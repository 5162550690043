import {
	CREATE_NOTIFICATION_START,
	CREATE_NOTIFICATION_SUCCESS,
	CREATE_NOTIFICATION_FAIL,
} from '../actions/actionTypes';
import utils from '../../services/utils';

const initialState = {
	isNotificationCreating: false
};

/* create notification start */
const createNotificationStart = (state, action) => {
	return utils.updateObject(state, {
		isNotificationCreating: true
	});
};

const createNotificationSuccess = (state, action) => {
	return utils.updateObject(state, {
		isNotificationCreating: false
	});
};

const createNotificationFail = (state, action) => {
	return utils.updateObject(state, {
		isNotificationCreating: false
	});
};
/* create notification end */

const notificationsReducer = (state = initialState, action = {}) => {
	switch (action.type) {
		case CREATE_NOTIFICATION_START: return createNotificationStart(state, action);
		case CREATE_NOTIFICATION_SUCCESS: return createNotificationSuccess(state, action);
		case CREATE_NOTIFICATION_FAIL: return createNotificationFail(state, action);

		default:
			return state;
	}
};

export default notificationsReducer;