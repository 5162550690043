/* Auth */
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';

export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

/* Categories */
export const GET_BUSINESS_CATEGORY_LIST_START = 'GET_BUSINESS_CATEGORY_LIST_START';
export const GET_BUSINESS_CATEGORY_LIST_SUCCESS = 'GET_BUSINESS_CATEGORY_LIST_SUCCESS';
export const GET_BUSINESS_CATEGORY_LIST_FAIL = 'GET_BUSINESS_CATEGORY_LIST_FAIL';

export const GET_BUSINESS_CATEGORY_BY_ID_START = 'GET_BUSINESS_CATEGORY_BY_ID_START';
export const GET_BUSINESS_CATEGORY_BY_ID_SUCCESS = 'GET_BUSINESS_CATEGORY_BY_ID_SUCCESS';
export const GET_BUSINESS_CATEGORY_BY_ID_FAIL = 'GET_BUSINESS_CATEGORY_BY_ID_FAIL';

export const EDIT_BUSINESS_CATEGORY_BY_ID_START = 'EDIT_BUSINESS_CATEGORY_BY_ID_START';
export const EDIT_BUSINESS_CATEGORY_BY_ID_SUCCESS = 'EDIT_BUSINESS_CATEGORY_BY_ID_SUCCESS';
export const EDIT_BUSINESS_CATEGORY_BY_ID_FAIL = 'EDIT_BUSINESS_CATEGORY_BY_ID_FAIL';

export const ORDER_BUSINESS_CATEGORY_LIST_START = 'ORDER_BUSINESS_CATEGORY_LIST_START';
export const ORDER_BUSINESS_CATEGORY_LIST_SUCCESS = 'ORDER_BUSINESS_CATEGORY_LIST_SUCCESS';
export const ORDER_BUSINESS_CATEGORY_LIST_FAIL = 'ORDER_BUSINESS_CATEGORY_LIST_FAIL';

/* Services */
export const GET_BUSINESS_SERVICE_LIST_START = 'GET_BUSINESS_SERVICE_LIST_START';
export const GET_BUSINESS_SERVICE_LIST_SUCCESS = 'GET_BUSINESS_SERVICE_LIST_SUCCESS';
export const GET_BUSINESS_SERVICE_LIST_FAIL = 'GET_BUSINESS_SERVICE_LIST_FAIL';

export const GET_BUSINESS_SERVICE_BY_ID_START = 'GET_BUSINESS_SERVICE_BY_ID_START';
export const GET_BUSINESS_SERVICE_BY_ID_SUCCESS = 'GET_BUSINESS_SERVICE_BY_ID_SUCCESS';
export const GET_BUSINESS_SERVICE_BY_ID_FAIL = 'GET_BUSINESS_SERVICE_BY_ID_FAIL';

export const EDIT_BUSINESS_SERVICE_BY_ID_START = 'EDIT_BUSINESS_SERVICE_BY_ID_START';
export const EDIT_BUSINESS_SERVICE_BY_ID_SUCCESS = 'EDIT_BUSINESS_SERVICE_BY_ID_SUCCESS';
export const EDIT_BUSINESS_SERVICE_BY_ID_FAIL = 'EDIT_BUSINESS_SERVICE_BY_ID_FAIL';

/* Users List */
export const GET_USERS_LIST_START = 'GET_USERS_LIST_START';
export const GET_USERS_LIST_SUCCESS = 'GET_USERS_LIST_SUCCESS';
export const GET_USERS_LIST_FAIL = 'GET_USERS_LIST_FAIL';

/* Specialists */
export const GET_SPECIALISTS_LIST_START = 'GET_SPECIALISTS_LIST_START';
export const GET_SPECIALISTS_LIST_SUCCESS = 'GET_SPECIALISTS_LIST_SUCCESS';
export const GET_SPECIALISTS_LIST_FAIL = 'GET_SPECIALISTS_LIST_FAIL';

export const GET_SPECIALISTS_ALL_LIST_START = 'GET_SPECIALISTS_ALL_LIST_START';
export const GET_SPECIALISTS_ALL_LIST_SUCCESS = 'GET_SPECIALISTS_ALL_LIST_SUCCESS';
export const GET_SPECIALISTS_ALL_LIST_FAIL = 'GET_SPECIALISTS_ALL_LIST_FAIL';

export const CREATE_SPECIALIST_NOTES_START = 'CREATE_SPECIALIST_NOTES_START';
export const CREATE_SPECIALIST_NOTES_SUCCESS = 'CREATE_SPECIALIST_NOTES_SUCCESS';
export const CREATE_SPECIALIST_NOTES_FAIL = 'CREATE_SPECIALIST_NOTES_FAIL';

/* Branches */
export const GET_BRANCHES_LIST_START = 'GET_BRANCHES_LIST_START';
export const GET_BRANCHES_LIST_SUCCESS = 'GET_BRANCHES_LIST_SUCCESS';
export const GET_BRANCHES_LIST_FAIL = 'GET_BRANCHES_LIST_FAIL';

export const GET_BRANCHES_ALL_LIST_START = 'GET_BRANCHES_ALL_LIST_START';
export const GET_BRANCHES_ALL_LIST_SUCCESS = 'GET_BRANCHES_ALL_LIST_SUCCESS';
export const GET_BRANCHES_ALL_LIST_FAIL = 'GET_BRANCHES_ALL_LIST_FAIL';

/* Banners */
export const GET_BANNERS_LIST_START = 'GET_BANNERS_LIST_START';
export const GET_BANNERS_LIST_SUCCESS = 'GET_BANNERS_LIST_SUCCESS';
export const GET_BANNERS_LIST_FAIL = 'GET_BANNERS_LIST_FAIL';

export const GET_BANNER_BY_ID_START = 'GET_BANNER_BY_ID_START';
export const GET_BANNER_BY_ID_SUCCESS = 'GET_BANNER_BY_ID_SUCCESS';
export const GET_BANNER_BY_ID_FAIL = 'GET_BANNER_BY_ID_FAIL';

export const CREATE_BANNER_START = 'CREATE_BANNER_START';
export const CREATE_BANNER_SUCCESS = 'CREATE_BANNER_SUCCESS';
export const CREATE_BANNER_FAIL = 'CREATE_BANNER_FAIL';

export const EDIT_BANNER_START = 'EDIT_BANNER_START';
export const EDIT_BANNER_SUCCESS = 'EDIT_BANNER_SUCCESS';
export const EDIT_BANNER_FAIL = 'EDIT_BANNER_FAIL';

/* Notifications */
export const CREATE_NOTIFICATION_START = 'CREATE_NOTIFICATION_START';
export const CREATE_NOTIFICATION_SUCCESS = 'CREATE_NOTIFICATION_SUCCESS';
export const CREATE_NOTIFICATION_FAIL = 'CREATE_NOTIFICATION_FAIL';