import React, {useState, useEffect, Fragment} from 'react';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import { getBranchesList, getBusinessCategoryList } from '../../store/actions';
import utils from '../../services/utils';
import config from '../../services/config';
import PropTypes from 'prop-types';
import './BranchesList.css';

import Loader from '../../components/UI/Loader/Loader';

function BranchesList({
		branches,
		businessCategoryList,
		loading,
		onGetBranchesList,
		onGetBusinessCategoryList
	}) {
	const [urlParams, urlParamsChange] = useState('');
	const [activePage, activePageChange] = useState(1);
	const [status, statusChange] = useState('-1');
	const [category_id, categoryChange] = useState('-1');
	const [has_specialist, hasSpecialistChange] = useState('-1');
	const [service_at_user_location, serviceAtUserLocationChange] = useState('-1');
	useEffect(() => onGetBranchesList(null, null),
		[onGetBranchesList]);

	useEffect(() => onGetBusinessCategoryList(),
		[onGetBusinessCategoryList]);

	useEffect(() => {
		const pageParams = utils.processUrlParams(urlParams, activePage);

		onGetBranchesList(pageParams.urlParams, null);
	}, [onGetBranchesList, activePage, urlParams]);

	const handlePageChange = page => {
		const pageParams = utils.processUrlParams(urlParams, page);

		urlParamsChange(pageParams.urlParams);
		activePageChange(page);
	};

	const handleFilterChange = (e, name) => {
		const pageParams = utils.processUrlParams(urlParams, 1, {[name]: e.target.value});

		urlParamsChange(pageParams.urlParams);
		activePageChange(1);
		switch(name) {
			case "status":
				statusChange(e.target.value);
				break;
			case "category_id":
				categoryChange(e.target.value);
				break;
			case "has_specialist":
				hasSpecialistChange(e.target.value);
				break;
			case "service_at_user_location":
				serviceAtUserLocationChange(e.target.value);
				break;
			default:
				return null;
		}
	};


	const branchesList = (branches._embedded && branches._embedded.branch) || [];
	const page_size = branches.page_size  || 25;
	const total_items = branches.total_items  || 1;

	return (
		<div className="container-fluid">
			<div className="card shadow">
				<div className="card-header py-3">
					<div className="row">
						<div className="col-sm-12 col-md-12">
							<h1 className="h3 text-gray-800">Branches</h1>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-12 col-md-3">
							<div className="form-group">
								<label>Status</label>
								<select className="form-control" id="status" value={status} onChange={e => handleFilterChange(e, "status")}>
									<option key="-1" value="-1">Select</option>
									{config.FILTER_STATUSES.map(e => <option key={e.value} value={e.value}>{e.label}</option>)}
								</select>
							</div>
						</div>
						<div className="col-sm-12 col-md-3">
							<div className="form-group">
								<label>Category</label>
								<select className="form-control" id="category_id" value={category_id} onChange={e => handleFilterChange(e, "category_id")}>
									<option key="-1" value="-1">Select</option>
									{businessCategoryList.map(e => <option key={e.id} value={e.id}>{e.title.text}</option>)}
								</select>
							</div>
						</div>
						<div className="col-sm-12 col-md-3">
							<div className="form-group">
								<label>Has Specialist</label>
								<select className="form-control" id="has_specialist" value={has_specialist} onChange={e => handleFilterChange(e, "has_specialist")}>
									<option key="-1" value="-1">Select</option>
									<option key="0" value="true">Yes</option>
									<option key="1" value="false">No</option>
								</select>
							</div>
						</div>
						<div className="col-sm-12 col-md-3">
							<div className="form-group">
								<label>Service At User Location</label>
								<select className="form-control" id="service_at_user_location" value={service_at_user_location} onChange={e => handleFilterChange(e, "service_at_user_location")}>
									<option key="-1" value="-1">Select</option>
									<option key="0" value="true">Yes</option>
									<option key="1" value="false">No</option>
								</select>
							</div>
						</div>
					</div>
				</div>
				<div className="card-body">
					<div className="row">
						{loading ?
							<Loader /> :
							<div className="col-sm-12 dataTables_container">
								<div className="dataTables_wrapper">
									<table className="table table-bordered dataTable" id="dataTable" width="100%" cellSpacing="0">
										{branchesList.length ?
											<Fragment>
												<thead>
													<tr role="row">
														<th>Name</th>
														<th>Address</th>
														<th className="col-categories">Categories</th>
														<th>Status</th>
														<th>Link</th>
														<th className="created-at">Created</th>
													</tr>
												</thead>
												<tfoot>
													<tr>
														<th>Name</th>
														<th>Address</th>
														<th className="col-categories">Categories</th>
														<th>Status</th>
														<th>Link</th>
														<th className="created-at">Created</th>
													</tr>
												</tfoot>
											</Fragment> : null
										}

										<tbody>
											{branchesList.map(item => (
												<tr role="row" className="odd" key={item.id}>
													<td>{item.name || 'N/A'}</td>
													<td>{item.address || 'N/A'}</td>
													<td>
														{
															item.categories && item.categories.length ?
																item.categories.map((c, i) => <span key={i}>{((c.title && c.title.translations && c.title.translations['eng']) || 'N/A') + (i === item.categories.length - 1 ? '' : ', ')}</span>) :
																'N/A'
														}
													</td>
													<td>{item.status || 'N/A'}</td>
													<td>
														<div className="copyClipboard" onClick={() => utils.copyCodeToClipboard('https://www.dobooki.com/app/?action=branch_booking&branch_id=' + item.id)}>
															<i className="fa fa-copy"></i>
														</div>
													</td>
													<td>{item.created_at || 'N/A'}</td>
												</tr>
											))}
										</tbody>

										{branchesList.length === 0 ?
											<tbody>
												<tr role="row" className="odd">
													<td colSpan="4">
														<div className="empty-list">List is empty</div>
													</td>
												</tr>
											</tbody> : null
										}
									</table>
								</div>
							</div>
						}
					</div>
					{loading || branchesList.length === 0 ?
						null :
						<div className="row">
							<div className="col-sm-12 col-md-5"></div>
							<div className="col-sm-12 col-md-7">
								<div className="dataTables_paginate paging_simple_numbers">
									<Pagination
										innerClass="pagination float-right"
										itemClass="page-item"
										linkClass="page-link"
										activePage={activePage}
										itemsCountPerPage={page_size}
										totalItemsCount={total_items}
										pageRangeDisplayed={5}
										onChange={handlePageChange}
										/>
								</div>
							</div>
						</div>
					}
				</div>
			</div>
		</div>
	);
}

BranchesList.propTypes = {
	businessCategoryList: PropTypes.array,
	branches: PropTypes.object,
	loading: PropTypes.bool,
	onGetBranchesList: PropTypes.func,
	onGetBusinessCategoryList: PropTypes.func
};

const mapStateToProps = state => {
	return {
		businessCategoryList: state.business.businessCategoryList,
		branches: state.branches.branches,
		loading: state.branches.isBranchesLoading
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetBranchesList: (urlParams, perPage) => dispatch(getBranchesList(urlParams, perPage)),
		onGetBusinessCategoryList: () => dispatch(getBusinessCategoryList())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BranchesList);