import API from '../../services/api/api';
import { toast } from 'react-toastify';
import moment from 'moment';
import utils from '../../services/utils';
import {
	LOGIN_START,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	UPDATE_USER_DATA,
	LOGOUT_START,
	LOGOUT_SUCCESS,
	LOGOUT_FAIL
} from './actionTypes';

/* login start */
const loginStart = () => {
	return {
		type: LOGIN_START
	};
};

const loginSuccess = user => {
	return {
		type: LOGIN_SUCCESS,
		user
	};
};

const loginFail = () => {
	return {
		type: LOGIN_FAIL
	};
};

const updateUserData = user => {
	return {
		type: UPDATE_USER_DATA,
		user
	};
};

export const login = userData => {
	return (dispatch, getState) => {
		dispatch(loginStart());
		API.login(userData)
			.then(response => {
				const userData = response.data;
				userData.expires_at = moment().add((userData.expires_in - 10), 'seconds').valueOf();

				dispatch(loginSuccess(userData));
				utils.setDataToStorage('TUK_TUK_USER_DATA', {...userData});
				refreshToken(dispatch, {...userData} );
				toast.success('Welcome back, you have successfully signed in', {
					position: toast.POSITION.TOP_RIGHT
				});
			})
			.catch( error => {
				console.log(error);
				dispatch(loginFail());
				toast.error(((error.response && error.response.data && error.response.data.detail) || 'Something went wrong'), {
					position: toast.POSITION.TOP_RIGHT
				});
			});
	};
};

export const updateUser = userData => {
	return (dispatch) => {
		console.log(userData.expires_at - moment().valueOf());
		if(userData.expires_at - moment().valueOf() > 0) {
			dispatch(updateUserData(userData));
			refreshToken(dispatch, userData);
		} else {
			dispatch(updateUserData({}));
			utils.deleteDataFromStorage('TUK_TUK_USER_DATA');
			toast.warning('Your session expired. Please login back again', {
				position: toast.POSITION.TOP_RIGHT
			});
		}
	}
};

const refreshToken = (dispatch, user) => {
	setTimeout(() => {
		API.refreshToken({ "grant_type": "refresh_token", "refresh_token": user.refresh_token })
			.then(response => {
				const userData = response.data;
				userData.expires_at = moment().add((userData.expires_in - 10), 'seconds').valueOf();

				dispatch(updateUserData(userData));
				utils.setDataToStorage('TUK_TUK_USER_DATA', {...userData});
				refreshToken(dispatch, userData );
			})
			.catch( error => {
				console.log(error);
				dispatch(updateUserData({}));
				utils.deleteDataFromStorage('TUK_TUK_USER_DATA');
				toast.error(((error.response && error.response.data && error.response.data.detail) || 'Something went wrong'), {
					position: toast.POSITION.TOP_RIGHT
				});

			});
	}, (user.expires_at - moment().valueOf()));
};
/* login end */



/* logout start */
const logoutStart = () => {
	return {
		type: LOGOUT_START
	};
};

const logoutSuccess = user => {
	return {
		type: LOGOUT_SUCCESS,
		user
	};
};

const logoutFail = () => {
	return {
		type: LOGOUT_FAIL
	};
};

export const logout = () => {
	return (dispatch, getState) => {
		dispatch(logoutStart());

		//TODO imitation backend call
		setTimeout(() => {
			utils.deleteDataFromStorage('TUK_TUK_USER_DATA');
			dispatch(logoutSuccess({}));
		}, 200);
	};
};
/* logout end */