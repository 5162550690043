import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { getBannerById, editBannerById, getBranchesAllList, getSpecialistsAllList } from '../../store/actions';
import PropTypes from 'prop-types';
import './Banner.css';

import Loader from '../../components/UI/Loader/Loader';

function Banner({
	branches,
	specialists,
	banner,
	isLoading,
	onGetBanner,
	onEditBanner,
	onGetSpecialistsAllList,
	onGetBranchesAllList,
	isEditing,
	location,
	history}) {

	useEffect(() => {
		const id = (location && location.pathname.split('/banner/')[1]) || null;
		onGetBanner(id).then(banner => {
			titleChange(banner.title || '');
			urlChange(banner.url || '');
			onGetSpecialistsAllList().then(specialists => {
				specialistChange((banner.specialist && banner.specialist.id) || '-1');
			});
			onGetBranchesAllList().then(branches => {
				branchChange(( banner.branch && banner.branch.id) || '-1');
			});
		});
	},
	[onGetBanner, onGetBranchesAllList, onGetSpecialistsAllList, location]);

	const [imgBase64, imgChange] = useState('');
	const [specialistId, specialistChange] = useState(-1);
	const [title, titleChange] = useState('');
	const [titleTouched, titleTouchedChange] = useState(false);
	const [url, urlChange] = useState('');
	const [branchId, branchChange] = useState(-1);
	const [status, statusChange] = useState('active');

	const handleFieldChange = (e, f, field) => {
		f(e.target.value);
		if(field === 'title') {
			titleTouchedChange(true);
		}
	};

	const handleFileUpload = e => {
		let file = e.target.files[0];
		if(file) {
			const reader = new FileReader();
			reader.onload = readerEvt => {
				var base64 = readerEvt.target.result;
				imgChange(base64);
			};
			reader.readAsDataURL(file);
		}
	};

	const handleSaveChanges = () => {
		const banner_id = banner.id|| null;
		const dataToSend = {title: title, url};
		if(specialistId != "-1") {
			dataToSend.specialist_id = +specialistId
		}
		if(branchId != "-1") {
			dataToSend.branch_id = +branchId
		}
		if(imgBase64.length) {
			dataToSend.image = imgBase64;
		}
		onEditBanner(dataToSend, banner_id).then(resp => {
			if(resp.status === 200) {
				history.push('/banners');
			}
		});
	};

	const disabled = !title;

	return (
		<Fragment>
			{isLoading ?
				<div className="loader-wrapper">
					<Loader />
				</div> :
				<Fragment>
					<div className="container-fluid fields-wrapper">
						<div className="mb-4">
							<h1 className="h3 mb-2 text-gray-800">{banner.title || ''}</h1>
							{banner.specialist && banner.specialist.user && banner.specialist.user.name ?
								<p>{banner.specialist.user.name}</p> : null
							}
						</div>

						<div className="row">
							<div className="col-lg-12">
								<div className="form-group">
									<label>Title<span>*</span></label>
									<input type="text"
										   className={"form-control " + (titleTouched && title.length === 0 ? "field-error" : "")}
										   id="title"
										   value={title}
										   onChange={e => handleFieldChange(e, titleChange, 'title')} />
									{
										titleTouched && title.length === 0 ?
											<div className="form-control-errors-wrapper">
												<p>Title is required.</p>
											</div> :
											null
									}

								</div>
							</div>

							<div className="col-sm-12 col-md-6">
								<div className="form-group">
									<label>Status</label>
									<select className="form-control" id="status" value={status} onChange={e => handleFieldChange(e, statusChange)}>
										<option key="0" value="active">Active</option>
										<option key="1" value="disabled">Disabled</option>
									</select>
								</div>
							</div>

							<div className="col-sm-12 col-md-6">
								<div className="form-group">
									<label>Url</label>
									<input type="text"
										   className="form-control"
										   id="url"
										   value={url}
										   onChange={e => handleFieldChange(e, urlChange, 'url')} />

								</div>
							</div>

							<div className="col-sm-12 col-md-6">
								<div className="form-group">
									<label>Specialists</label>
									<select className="form-control" id="status" value={specialistId} onChange={e => handleFieldChange(e, specialistChange)}>
										<option key={-1} value={-1}>Select</option>
										{(specialists || []).map(e => <option key={e.id} value={e.id}>{e.name}</option>)}
									</select>
								</div>
							</div>

							<div className="col-sm-12 col-md-6">
								<div className="form-group">
									<label>Branches</label>
									<select className="form-control" id="status" value={branchId} onChange={e => handleFieldChange(e, branchChange)}>
										<option key={-1} value={-1}>Select</option>
										{(branches || []).map(e => <option key={e.id} value={e.id}>{e.name}</option>)}
									</select>
								</div>
							</div>

							<div className="col-lg-12">
								<input type="file"
									   className="form-control"
									   id="file"
									   name="image"
									   accept=".jpg, .jpeg, .png"
									   onChange={handleFileUpload} />
								<label htmlFor="file">choose a file</label>
							</div>
						</div>

						<div className="row">
							<div className="col-lg-12">
								<img className="img-fluid uploading-img" src={imgBase64 || banner.image_url} alt={banner.title} />
							</div>
						</div>
					</div>

					<div className="container-fluid">
						<div className="row">
							<div className="col-lg-12">
								<button type="submit"
										className="btn btn-primary mb-2 float-left"
										onClick={() => history.push('/banners')}>Back</button>
								<button type="submit"
										disabled={disabled || isEditing}
										className="btn btn-primary mb-2 float-right"
										onClick={handleSaveChanges}>Save</button>
							</div>
						</div>
					</div>

				</Fragment>
			}
		</Fragment>

	);
}

Banner.propTypes = {
	banner: PropTypes.object,
	specialists: PropTypes.array,
	branches: PropTypes.array,
	isLoading: PropTypes.bool,
	isEditing: PropTypes.bool,
	onGetBanner: PropTypes.func,
	onEditBanner: PropTypes.func,
	onGetSpecialistsAllList: PropTypes.func,
	onGetBranchesAllList: PropTypes.func
};

const mapStateToProps = state => {
	return {
		banner: state.banners.banner,
		specialists: state.specialists.specialistsAll,
		branches: state.branches.branchesAll,
		isLoading: state.banners.isBannerLoading || state.branches.isBranchesAllLoading,
		isEditing: state.banners.isBannerEditing
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetBanner: id => dispatch(getBannerById(id)),
		onEditBanner: (data, id) => dispatch(editBannerById(data, id)),
		onGetSpecialistsAllList: () => dispatch(getSpecialistsAllList()),
		onGetBranchesAllList: () => dispatch(getBranchesAllList())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Banner);